import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { History } from "@/models/history/history";
export const useHistoryStore = defineStore("ActivityHistory", () => {
  const historyContent = ref<History[]>([]);

  const getHistory = computed(() => {
    return historyContent.value.sort((a, b) => {
      // if an item has recall date, compare by that, otherwise use regular dates
      const aDate = a.DateRecalled ? new Date(a.DateRecalled) : new Date(a.Date);
      const bDate = b.DateRecalled ? new Date(b.DateRecalled) : new Date(b.Date);
      return bDate.getTime() - aDate.getTime();
    });
  });

  const displayedContentCount = computed(() => {
    return historyContent.value.length;
  });

  function setHistory (content) {
    historyContent.value = content;
  }

  function updateRecalledItems (recalledItems: History[]) {
    recalledItems.forEach(item => {
      const index = historyContent.value.findIndex(el => el.UniqueDocumentId === item.UniqueDocumentId);
      historyContent.value[index].IsRecalled = true;
      historyContent.value[index].DateRecalled = new Date().toISOString();
    });
  }

  return { historyContent, getHistory, displayedContentCount, setHistory, updateRecalledItems };
});
