import { render, staticRenderFns } from "./SessionDetails.vue?vue&type=template&id=134f2853&scoped=true"
import script from "./SessionDetails.vue?vue&type=script&lang=ts"
export * from "./SessionDetails.vue?vue&type=script&lang=ts"
import style0 from "./SessionDetails.vue?vue&type=style&index=0&id=134f2853&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "134f2853",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VContainer,VOverlay,VProgressCircular,VSimpleTable})
