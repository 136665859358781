
import { defineComponent, ref, watch, computed, WritableComputedRef } from "vue";
import { usePatientStore } from "@/store/modules/Patient/patient";
import { usePrintShareStore } from "@/store/modules/PrintShare/printShare";

export default defineComponent({
  name: "EmailNotificationDialog",
  setup () {
    const PrintShare = usePrintShareStore();
    const PatientModule = usePatientStore();
    const isIE = () => { const userAgent = navigator.userAgent; return /MSIE|Trident/.test(userAgent); };
    const isDialogVisible: WritableComputedRef<boolean> = computed({
      get (): boolean {
        return PrintShare.getShowEmailDialog;
      },
      set (triggerEmailNotification: boolean): void {
        PrintShare.printOrShareMaterial({ triggerEmailNotification });
        if (isIE()) { setTimeout(() => { (document.activeElement as HTMLElement).blur(); }, 50); }
      },
    });
    const email = ref("");
    const isFormValid = ref(false);
    const emailRules = ref([
      v => !!v || "",
      v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Please add a valid email",
    ]);
    const emailFromRecord = computed(() => {
      return PatientModule.getEmail;
    });
    watch(() => emailFromRecord.value, (n) => {
      if (n) {
        email.value = n;
      }
    });
    const saveEmail = (): void => {
      PatientModule.setEmail(email.value);
      isDialogVisible.value = false;
    };

    return { isDialogVisible, email, emailRules, isFormValid, emailFromRecord, saveEmail };
  },
});
