import { Ref, ref } from "vue";
import router from "@/router/index";
import { useErrorPage } from "./errorPage";

// Services
import UserService from "@/services/userService";
import UIConfigurationService from "@/services/uiConfigurationService";
import ContentService from "@/services/contentService";
import PatientService from "@/services/patientService";
import EducationCartService from "@/services/educationCartService";

// Store Modules
import { useUserStore } from "@/store/modules/User/user";
import { useIdleTimeStore } from "@/store/modules/IdleTime/idleTime";
import { useUIConfigurationStore } from "@/store/modules/UIConfiguration/uiConfiguration";
import { useContentTypesStore } from "@/store/modules/ContentTypes/contentTypes";
import { usePatientStore } from "@/store/modules/Patient/patient";
import { useEducationCartStore } from "@/store/modules/EducationCart/educationCart";
import { useContentStore } from "@/store/modules/Content/content";
import { useTableSettingsStore } from "@/store/modules/TableSettings/tableSettings";

// Data Models
import { UIConfigurationModel } from "@/models/uiConfiguration/uiConfiguration";
import { PatientModel } from "@/models/patient/patient";
import { EducationCartModel } from "@/models/educationCart/educationCart";

const samlDiagnostics = ref(false);
const { showErrorPage } = useErrorPage();
const setInitialStates = (state: string, endpoint: string, loading: Ref<boolean>, tenantId: Ref<string>) => {
  if (endpoint === "diagnostics-saml") {
    samlDiagnostics.value = true;
    router.push({ path: "/saml-diagnostics", query: { state } });
    loading.value = false;
    return;
  }
  const throwError = (e: Error) => {
    console.error("el error: ", e);
    loading.value = false;
    const errorId = router.currentRoute.query.errorId as string;
    showErrorPage("Service unavailable.", errorId);
  };

  const userPromise = UserService.getUserSettings().then(user => {
    const UserModule = useUserStore();
    const IdleTimeModule = useIdleTimeStore();
    const TableStore = useTableSettingsStore();
    UserModule.setUserSettings(user);
    UserModule.setPatientPortalSettings(user.patientPortalEnabled);
    UserModule.setDocumentEditingEnabled(user.documentEditingEnabled);
    TableStore.setTableView(user.userSettings.SearchLayoutType);
    TableStore.updateSelectedRowsPerPage(TableStore.view === "list" ? UserModule.getUserSettings.RowsPerPage ?? TableStore.listRowsPerPage[1] : UserModule.getUserSettings.CardsPerPage ?? TableStore.gridRowsPerPage[1]);
    if (UserModule.getSessionSettings?.expirationTimeout > 0 &&
      UserModule.getSessionSettings?.expirationNoticeWindow > 0) {
      IdleTimeModule.watchIdleTime({ expirationTimeout: UserModule.getSessionSettings.expirationTimeout, expirationNoticeWindow: UserModule.getSessionSettings.expirationNoticeWindow });
    }
  });

  const uiConfigurationPromise = UIConfigurationService.information().then(res => {
    const UIConfigurationModule = useUIConfigurationStore();
    UIConfigurationModule.setUIConfiguration(res as UIConfigurationModel);
  });

  const contentPromise = ContentService.getContentTypes().then(async (res) => {
    const contentTypesStore = useContentTypesStore();
    contentTypesStore.setContentTypes(res);
  });

  const patientPromise = PatientService.getPatientInfo().then(res => {
    const PatientModule = usePatientStore();
    PatientModule.setPatient(res as PatientModel);
  }).catch(err => {
    throwError(err);
  });

  const cartPromise = EducationCartService.getEducationCart().then(res => {
    const CartModule = useEducationCartStore();
    const ContentModule = useContentStore();
    CartModule.setCart(res as EducationCartModel);
    ContentModule.updateSelectedMaterials(res.CartItems);
  });

  Promise.all([userPromise, uiConfigurationPromise, contentPromise, patientPromise, cartPromise]).then(() => {
    const UserModule = useUserStore();
    const timestamp = Date.now ? Date.now() : new Date().getTime();
    const displaySettings = UserModule.getDisplaySettings;
    loading.value = false;
    if (endpoint === "diagnostics") {
      router.push({ path: "/diagnostics", query: { state, refresh: timestamp.toString() } });
    } else if (displaySettings.landingRedirect) {
      router.push({ path: displaySettings.landingRedirect, query: { state, refresh: timestamp.toString() } });
    } else {
      if (router.currentRoute.name !== "PatientEncounter") {
        router.push({ name: "PatientEncounter", params: { tenantId: tenantId.value }, query: { state } });
      }
    }
  }).catch(err => {
    throwError(err);
  });
};

const useInitialAuthenticationStates = () => {
  return { setInitialStates, samlDiagnostics };
};

export { useInitialAuthenticationStates };
