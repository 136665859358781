
import { defineComponent, ref, computed } from "vue";
import convertDate from "../utility/convertDate";
import shareDateComparison from "../utility/DateComparisonStyle";
import getIndicator from "../utility/getIndicator";
import CustomIcon from "./CustomIcon.vue";
import PercentViewedBadge from "./PercentViewedBadge.vue";
import { useUIConfigurationStore } from "@/store/modules/UIConfiguration/uiConfiguration";
import PreviewModalFunctionality from "@/composables/PreviewModalFunctionality";

export default defineComponent({
  name: "HistoryRow",
  components: { CustomIcon, PercentViewedBadge },
  props: ["material", "displaySettings", "index"],
  setup (props) {
    const UIConfigurationModule = useUIConfigurationStore();
    const AvsRecallInfo = UIConfigurationModule.getAvsRecallInfo;
    const rowExpanded = ref(false);
    const { isOpenInPreview } = PreviewModalFunctionality(props);
    const shareType = computed(() => {
      return props.material.PrescribedType === "Shared" ? "Share" : "Print and Share";
    });
    return {
      convertDate,
      getIndicator,
      shareDateComparison,
      AvsRecallInfo,
      rowExpanded,
      shareType,
      isOpenInPreview,
    };
  },
});
