import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { UIConfigurationModel } from "../../../models/uiConfiguration/uiConfiguration";

export const useUIConfigurationStore = defineStore("UIConfiguration", () => {
  const uiConfiguration = ref<UIConfigurationModel>({
    ShowPrintPublishModal: false,
    ShowPublishModal: false,
    ShowPortalPluginEmailModal: false,
    ShowAvsCheckbox: false,
    ShowTaxonomyCounts: false,
    DisplayPrintOnlyOption: false,
    PrioritizeByFavorite: false,
    hideConditionsTabSpecialMessage: false,
    hideImpressionsTabSpecialMessage: false,
    HidePatientAge: false,
    AvsRecallEnabled: false,
    AvsRecallTime: "",
    ContentTypesEnabledForEdit: [],
    UseContentTypeGroups: false,
  });
  const cartLimitExceededDialogVisibility = ref(false);

  const getUIConfiguration = computed(() => uiConfiguration.value);
  const getCartLimitExceededDialogVisibility = computed(() => cartLimitExceededDialogVisibility.value);
  const getAvsRecallInfo = computed(() => {
    const AvsRecallEnabled = uiConfiguration.value?.AvsRecallEnabled;
    const AvsRecallTime = uiConfiguration.value?.AvsRecallTime;
    return { AvsRecallEnabled, AvsRecallTime };
  });

  function setUIConfiguration (uiConfigurationInfo: UIConfigurationModel) {
    uiConfiguration.value = uiConfigurationInfo;
  }

  function setCartLimitExceededDialogVisibility (flag: boolean) {
    cartLimitExceededDialogVisibility.value = flag;
  }

  return { uiConfiguration, cartLimitExceededDialogVisibility, getUIConfiguration, getCartLimitExceededDialogVisibility, getAvsRecallInfo, setUIConfiguration, setCartLimitExceededDialogVisibility };
});
