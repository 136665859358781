
import { defineComponent, computed, ref, WritableComputedRef, watch } from "vue";
import { useContentStore } from "@/store/modules/Content/content";
import { useEducationCartStore } from "@/store/modules/EducationCart/educationCart";
import { usePrintShareStore } from "@/store/modules/PrintShare/printShare";
import { ContentList } from "../models/content/content";
import PrintMenuLarge from "./PrintMenuLarge.vue";
import { useUIConfigurationStore } from "@/store/modules/UIConfiguration/uiConfiguration";
import { useUserStore } from "@/store/modules/User/user";
import printShareFunctionality from "../composables/PrintOrShare";
import ShareIconOutlined from "./icons/ShareIconOutlined.vue";

export default defineComponent({
  name: "MultiSelect",
  components: {
    PrintMenuLarge,
    ShareIconOutlined,
  },
  setup () {
    const PrintShareModule = usePrintShareStore();
    const ContentModule = useContentStore();
    const UiConfigurationModule = useUIConfigurationStore();
    const UserModule = useUserStore();
    const CartModule = useEducationCartStore();
    const isMultiselectOpened = ref(false);
    const multiselect = ref(1);

    const { isSharing, isPrinting, printOrShareMaterials } = printShareFunctionality();
    const userDisplaySettings = computed(() => UserModule.getDisplaySettings);
    const uiConfiguration = computed(() => UiConfigurationModule.uiConfiguration);

    const selectedMaterials: WritableComputedRef<ContentList[]> = computed({
      get () {
        const selected = ContentModule.getselectedMaterialsArray;
        selected.length > 0 ? isMultiselectOpened.value = true : isMultiselectOpened.value = false;
        return selected;
      },
      set (newValue): void {
        ContentModule.updateSelectedMaterials(newValue);
      },
    });

    const clearSelectedMaterialsList = () => {
      return ContentModule.updateSelectedMaterials([]);
    };

    const removeFromSelected = (material) => {
      return ContentModule.removeFromSelected(material);
    };

    const showPrintButton = computed((): boolean => {
      return UserModule.getDisplaySettings.printButton?.visibility === 1;
    });

    const printMaterials = async (print) => {
      print ? isPrinting.value = true : isSharing.value = true;
      await PrintShareModule.printOrShareMaterial({ materials: ContentModule.getselectedMaterialsArray, isPrint: print, multiPrint: true });
      print ? isPrinting.value = false : isSharing.value = false;
    };

    watch(() => selectedMaterials.value, (newvalue, oldvalue) => {
      if (JSON.stringify(newvalue) !== JSON.stringify(oldvalue)) {
        if (newvalue.length === 0) {
          isMultiselectOpened.value = false;
        } else {
          isMultiselectOpened.value = true;
        }
        CartModule.updateCart({ settingName: "CartItems", value: newvalue });
      }
    });

    return {
      selectedMaterials,
      multiselect,
      isMultiselectOpened,
      isPrinting,
      isSharing,
      clearSelectedMaterialsList,
      removeFromSelected,
      printMaterials,
      showPrintButton,
      printOrShareMaterials,
      uiConfiguration,
      userDisplaySettings,
    };
  },
});
