
import { defineComponent, computed, reactive, WritableComputedRef } from "vue";
import { useVuetify } from "@/utility/useVuetify";
import cssVars from "css-vars-ponyfill";

import NavDrawer from "./NavDrawer.vue";

import { usePatientStore } from "@/store/modules/Patient/patient";
import { useUserStore } from "@/store/modules/User/user";
import { useAuthStore } from "@/store/modules/Auth/auth";
import { useUIConfigurationStore } from "@/store/modules/UIConfiguration/uiConfiguration";

export default defineComponent({
  name: "Header",
  components: {
    NavDrawer,
  },
  setup (_props, { emit }) {
    const AuthModule = useAuthStore();
    const uiConfiguration = useUIConfigurationStore();
    const PatientModule = usePatientStore();
    const UserModule = useUserStore();
    const vuetify = useVuetify();
    const darkMode: WritableComputedRef<boolean> = computed({
      get (): boolean {
        return UserModule.user?.userSettings?.DarkModeEnabled;
      },
      set (newValue: boolean): void {
        UserModule.updateDarkModeEnabled(newValue);
        // cssVars handles css custom properties in IE 11.
        cssVars();
      },
    });
    const patient = computed(() => {
      return PatientModule.getPatient;
    });
    const companyLabel = computed(() => {
      return UserModule.getCompanyLabel;
    });
    const productLabel = computed(() => {
      return UserModule.getProductLabel;
    });
    const hiddenIconNames = computed((): Array<string> => {
      return UserModule.getDisplaySettings.hiddenMenuIconNames;
    });
    const isDrawerVisible = reactive({ isVisible: false });

    const isSessionExpired = computed(() => {
      return AuthModule.getTokenStatus;
    });

    const isPatientAgeHidden = computed(() => {
      return uiConfiguration.getUIConfiguration.HidePatientAge;
    });

    const isPatientEncounterVisible = computed(() => PatientModule.isPatientEncounterVisible);

    const setIsPatientEncounterVisible = (status: boolean) => {
      PatientModule.setIsPatientEncounterVisible(status);
    };

    const showMenuIcon = (buttonName: string) => hiddenIconNames.value.filter(menu => menu.toLowerCase() === buttonName.toLowerCase()).length === 0;

    const loadHelpDialog = () => {
      emit("openHelpDialog");
    };
    const isPatientPortalEnabled = computed(() => {
      return UserModule.getPatientPortalSettings;
    });
    const patientEmail = computed(() => {
      return PatientModule.getEmail;
    });

    const loadEmailDialog = () => {
      emit("openEmailDialog");
    };

    const backToHomePage = () => {
      setIsPatientEncounterVisible(true);
      emit("backToHomePage");
    };

    const refresh = () => {
      emit("refresh");
    };

    const toggleDarkMode = () => {
      if (vuetify) {
        vuetify.theme.dark = !vuetify.theme.dark;
        darkMode.value = vuetify.theme.dark;
      }
    };

    return {
      patient,
      companyLabel,
      productLabel,
      isDrawerVisible,
      loadHelpDialog,
      backToHomePage,
      isPatientAgeHidden,
      isPatientPortalEnabled,
      isSessionExpired,
      patientEmail,
      loadEmailDialog,
      refresh,
      showMenuIcon,
      hiddenIconNames,
      setIsPatientEncounterVisible,
      isPatientEncounterVisible,
      darkMode,
      toggleDarkMode,
    };
  },
});
