import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { Content, ContentList, Edited, PublishedMaterial, TaxonomyCodeToTitles, createEditedItem } from "@/models/content/content";
import { useFoldersStore } from "../Folders/folders";
import { usePatientStore } from "../Patient/patient";
import FavoriteService from "@/services/favoritesService";
import createFrontEndId from "@/utility/createFrontEndId";
import mapContentToFavorite from "@/utility/favoriteMapper";
export const useContentStore = defineStore("ContentModule", () => {
  const content = ref<Content[]>([]);
  const selectedMaterials = ref({});
  const publishedHistory = ref({});
  const favorites = ref({});
  const edited = ref<Edited[]>([]);
  const searchResults = ref<ContentList[]>([] as ContentList[]); // have to do weird casting inside for ts later in code
  const isPageFavorites = ref(false);
  const displayedContentCount = ref(0);
  const previewedMaterialIndex = ref(0);
  const taxonomyCodeToTitles = ref<TaxonomyCodeToTitles[]>([]);

  const getContent = computed(() => content.value);
  const getPreviewedMaterialIndex = computed(() => previewedMaterialIndex.value);
  const getTaxonomyTitleCount = computed(() => (taxonomyCode) => {
    const taxToTitles = taxonomyCodeToTitles.value.find(taxToTitles => taxToTitles.TaxonomyCode === taxonomyCode);
    let count = 0;
    if (taxToTitles) {
      count = taxToTitles.Titles;
    }
    return count;
  });
  const getSelectedMaterials = computed(() => selectedMaterials.value);
  const getselectedMaterialsArray = computed((): ContentList[] => {
    // for (const m in selectedMaterials.value) materialsArray.push(selectedMaterials.value[m]);
    // console.log("materialsArray", materialsArray, "selectedMaterials", selectedMaterials.value);
    return Object.values(selectedMaterials.value);
  });

  const getFavorites = computed(() => favorites.value);
  const getFavoritesLength = computed(() => Object.entries(favorites.value).length);
  const favoritesDisplayedContentCount = computed(() => Object.keys(favorites.value).length);
  const getEdited = computed(() => edited.value);
  const getFavoritesContentArray = computed(() => {
    const contentItems: ContentList[] = [];
    Object.keys(favorites.value).forEach(key => {
      if (favorites.value[key].ContentItem != null) {
        contentItems.push(favorites.value[key].ContentItem);
      }
    });
    contentItems.sort((a, b) => (a.Title < b.Title ? -1 : (a.Title > b.Title ? 1 : 0)));
    return contentItems;
  });
  const needToLoadFavoriteContent = computed(() => {
    const favoritesObject = favorites.value;
    // if there are no items in the favorites object, this could be a
    // saml/care connector launch, so return true to double check
    if (Object.keys(favoritesObject).length === 0) {
      return true;
    }
    // if there are any favorites in the favorites object
    // that we don't already have the content for, return true
    for (const key of Object.keys(favoritesObject)) {
      if (favoritesObject[key].ContentItem == null) {
        return true;
      }
    }
    return false;
  });

  // other functions that aren't 'actions' but were mutations in vuex
  function pushContentToState (newContent) {
    if (!content.value) content.value = [];
    const taxonomyContent = content.value.find(item => item.Taxonomies[0].Code === newContent.Taxonomies[0].Code);
    if (taxonomyContent) {
      // first empty, then push
      taxonomyContent.ContentList.length = 0;
      taxonomyContent.ContentList.push(...newContent.ContentList);
      taxonomyContent.TotalResults = newContent.TotalResults;
    } else {
      content.value.push(newContent);
    }
  };

  function toggleFavoriteInState ({ material, isFavorite, contentOrigin }) {
    const newFavorite = favorites.value;
    if (isFavorite) {
      const favoriteContent = mapContentToFavorite(material);
      favoriteContent.ContentItem = { ...favoriteContent.ContentItem, ContentOrigin: contentOrigin };
      newFavorite[material.FrontEndId] = favoriteContent;
    } else {
      delete newFavorite[material.FrontEndId];
    }
    favorites.value = { ...newFavorite };
  }

  function addToEdited ({ contentId, contentTypeId }) {
    const newItem = createEditedItem({ contentId, contentTypeId });
    edited.value.push(newItem);
  }

  function removeFromEdited ({ contentId }) {
    const index = edited.value.findIndex(editedItems => editedItems.contentId === contentId);
    edited.value.splice(index, 1);
  }

  // actions
  function updatePublishedHistory (contentToUpdate) {
    contentToUpdate.forEach(item => {
      const frontEndId = createFrontEndId(item);
      const content = publishedHistory.value[frontEndId];
      content.isRecalled = true;
      content.dateRecalled = new Date().toISOString();
      content.patientPrescribedType = "Recalled";
    });
  }

  function clearContent () {
    content.value = [];
  }

  function clearTitleCounts () {
    taxonomyCodeToTitles.value = [];
  }

  function pushTitleCounts (titleCountsForTaxonomy) {
    const { TaxonomyCode, Titles } = titleCountsForTaxonomy;
    const index = taxonomyCodeToTitles.value.findIndex(t => t.TaxonomyCode === TaxonomyCode);
    if (index !== -1) {
      taxonomyCodeToTitles.value[index].Titles = Titles;
    } else {
      taxonomyCodeToTitles.value.push({ TaxonomyCode, Titles });
    }
  }

  function pushContent (content) {
    if (!content) return;
    if (!content.ContentList) content.ContentList = [];
    const PatientModule = usePatientStore();
    content.ContentList.forEach(m => {
      const preferredLanguage = PatientModule.patient.PreferredLanguageCode;
      const isInLanguages = m.OtherLanguages.findIndex(l => l.Code === preferredLanguage) > -1;
      if (m.LanguageCode !== preferredLanguage && isInLanguages) m.LanguageCode = preferredLanguage;
      const isEdited = edited.value.findIndex(c => c.contentId === m.ContentId) > -1;
      if (isEdited) m.IsInlineEdited = true;
      m.FrontEndId = createFrontEndId(m);
    });
    pushContentToState(content);
    pushTitleCounts({ TaxonomyCode: content.Taxonomies[0].Codings[0].Code, Titles: content.TotalResults });
  }

  function updateFavorites (newFavorites) {
    const favoritesObject = newFavorites.reduce((previousValue, currentValue) => {
      currentValue.FrontEndId = createFrontEndId(currentValue);
      if (!previousValue[currentValue.FrontEndId]) previousValue[currentValue.FrontEndId] = currentValue;
      for (let i = 0; i < edited.value.length; i++) {
        if (edited.value[i].contentId === currentValue.ContentId) {
          currentValue.IsInlineEdited = true;
          break;
        }
      }
      return previousValue;
    }, {});

    favorites.value = favoritesObject;
  };

  function updateFavoritesContent (favoritesContent) {
    favoritesContent.forEach(f => { f.FrontEndId = createFrontEndId(f); });
    const favorites = favoritesContent.map(f => mapContentToFavorite(f));
    updateFavorites(favorites);
  }

  function setPublishedMaterials (materials: PublishedMaterial[]) {
    if (!materials || materials.length === 0) return;
    materials.sort((a: PublishedMaterial, b: PublishedMaterial) => {
      return new Date(b.patientPrescribedDate).getTime() - new Date(a.patientPrescribedDate).getTime();
    });
    const publishedMaterials = materials.reduce((previousValue, currentValue) => {
      currentValue.FrontEndId = createFrontEndId(currentValue);
      if (!previousValue[currentValue.FrontEndId]) previousValue[currentValue.FrontEndId] = currentValue;
      return previousValue;
    }, {});

    publishedHistory.value = publishedMaterials;
  }

  async function toggleFavorite ({ material, contentTypeId, isFavorite }) {
    const response = isFavorite
      ? await FavoriteService.setMaterialAsFavorite({ ContentId: material.ContentId, contentTypeId })
      : await FavoriteService.removeFavorite(material.ContentId, contentTypeId);
    if (!response) return;

    toggleFavoriteInState({ material, isFavorite, contentOrigin: response.ContentOrigin });
  }

  function updateEdited (editedContent: Edited[]) {
    if (editedContent !== null) {
      edited.value = [];
      editedContent.forEach(item => {
        // backend uses contentId, but ContentId is used all over the front end
        item.ContentId = item.contentId;
      });
      edited.value = editedContent;
    }
  }

  function toggleEdited (data) {
    const { contentId, taxonomyCode, isEdited, contentTypeId } = data;
    if (taxonomyCode && taxonomyCode !== "Folders" && taxonomyCode !== "Favorites") {
      const contentItems = content.value.find((t: Content) => t.Taxonomies[0].Code === taxonomyCode);

      if (contentItems) {
        contentItems.ContentList.find(c => c.ContentId === contentId).IsInlineEdited = isEdited;
      }
    } else {
      let contentItem;
      for (let i = 0; i < content.value.length; i++) {
        const contentFromStore = content.value[i];
        for (let j = 0; j < contentFromStore.ContentList.length; j++) {
          const listItem = contentFromStore.ContentList[j];
          if (listItem.ContentId === contentId) {
            contentItem = listItem;
            break;
          }
        }
        if (contentItem) {
          break;
        }
      }
      if (contentItem) {
        contentItem.IsInlineEdited = isEdited;
      }
    }

    // toggle the folder material if there is one, and update search items and favorites
    const FoldersModule = useFoldersStore();
    FoldersModule.toggleEdited({ contentId, isEdited });
    const searchItem = searchResults.value.find(c => c.ContentId === contentId);
    if (searchItem) {
      searchItem.IsInlineEdited = isEdited;
    }

    const favoritesValues: ContentList[] = Object.values(favorites.value);
    const favoriteMaterial: ContentList | undefined = favoritesValues.find((favorite: ContentList) => favorite.ContentId === contentId);
    if (favoriteMaterial) {
      favoriteMaterial.IsInlineEdited = isEdited;
    }

    if (isEdited) {
      addToEdited({ contentId, contentTypeId });
    } else {
      removeFromEdited({ contentId });
    }
  }

  function addToSelected (material) {
    const selected = selectedMaterials.value;
    selected[material.FrontEndId] = material;
    selectedMaterials.value = { ...selected };
  }

  function removeFromSelected (material) {
    const selected = selectedMaterials.value;
    delete selected[material.FrontEndId];
    selectedMaterials.value = { ...selected };
  }

  function updateSelectedMaterials (materials) {
    const newMaterials = materials.reduce((previousValue, currentValue) => {
      currentValue.FrontEndId = createFrontEndId(currentValue);
      if (!previousValue[currentValue.FrontEndId]) previousValue[currentValue.FrontEndId] = currentValue;
      return previousValue;
    }, {});

    selectedMaterials.value = newMaterials;
  }

  function updateDisplayedContentCount (count) {
    displayedContentCount.value = count;
  }

  function changeLanguage ({ material, language }) {
    material.LanguageCode = language.Code;
    material.Language = language;
    material.ContentId = language.ContentId;
    material.FrontEndId = createFrontEndId(material);

    if (!material.Taxonomy && isPageFavorites.value) return;

    if (!material.Taxonomy && !isPageFavorites.value) {
      searchResults.value.map(c => {
        if (c.ContentId === material.ContentId) {
          c.LanguageCode = language.Code;
          c.Language = language;
          c.ContentId = language.ContentId;
        }
      });
      return;
    }

    const contentItem = content.value.find((t: Content) => t.Taxonomies[0].Code === material.Taxonomy.Code);

    if (contentItem) {
      contentItem.ContentList.map(c => {
        if (c.ContentId === material.ContentId) {
          c.LanguageCode = language.Code;
          c.Language = language;
          c.ContentId = language.ContentId;
        }
      });
    }
  }

  function updateSearchResults (contentList) {
    contentList.forEach(c => {
      c.FrontEndId = createFrontEndId(c);
      for (let i = 0; i < edited.value.length; i++) {
        if (edited.value[i].contentId === c.ContentId) {
          c.IsInlineEdited = true;
          break;
        }
      }
    });

    searchResults.value = contentList;
  }

  function setPage (flag) {
    isPageFavorites.value = flag;
  }

  function setPreviewedMaterialIndex (index) {
    previewedMaterialIndex.value = index;
  }

  function getContentById (contentId) {
    const materialContent = content.value.flatMap(c => c.ContentList.find(m => m.ContentId === contentId));
    return materialContent.length > 1 ? materialContent[0] : null;
  }

  return { content, selectedMaterials, publishedHistory, favorites, edited, searchResults, isPageFavorites, displayedContentCount, previewedMaterialIndex, taxonomyCodeToTitles, getContent, getPreviewedMaterialIndex, getTaxonomyTitleCount, getSelectedMaterials, getselectedMaterialsArray, getFavorites, getFavoritesLength, favoritesDisplayedContentCount, getEdited, getFavoritesContentArray, needToLoadFavoriteContent, pushContentToState, toggleFavoriteInState, addToEdited, removeFromEdited, updatePublishedHistory, clearContent, clearTitleCounts, pushTitleCounts, pushContent, updateFavorites, updateFavoritesContent, setPublishedMaterials, toggleFavorite, updateEdited, toggleEdited, addToSelected, removeFromSelected, updateSelectedMaterials, updateDisplayedContentCount, changeLanguage, updateSearchResults, setPage, setPreviewedMaterialIndex, getContentById };
});
