import { defineStore } from "pinia";
import { ref, computed } from "vue";
import AuthService from "../../../services/authService";
import { TokenData } from "@/models/authentication/accessToken";

export const useAuthStore = defineStore("Auth", () => {
  const ehrName = ref("");
  const token = ref("");
  const stateId = ref("");
  const tokenRefreshTimeout = ref(null);
  const isTokenExpired = ref(false);
  const isDialogVisible = ref(false);
  const tokenExpiresAt = ref(0);
  const tokenIssuedAt = ref(0);

  const getEhrName = computed(() => ehrName.value);
  const getTokenStatus = computed(() => isTokenExpired.value);
  const getToken = computed(() => token.value);
  const getStateid = computed(() => stateId.value);
  const getTimerId = computed(() => tokenRefreshTimeout.value);
  const getIsDialogVisible = computed(() => isDialogVisible.value);

  function setIsDialogVisible (isVisible: boolean) {
    isDialogVisible.value = isVisible;
  }

  function setEhrName (name: string) {
    ehrName.value = name;
  }

  function setTokenData (tokenData: TokenData) {
    token.value = tokenData.BearerToken;
    tokenIssuedAt.value = tokenData.BearerTokenIssuedAt ?? 0;
    tokenExpiresAt.value = tokenData.BearerTokenExpiresAt ?? 0;
  }

  function setStateId (id: string) {
    stateId.value = id;
  }

  function parseTokenToObject (accessToken: string) {
    if (!accessToken) return null;
    const base64Url = accessToken.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    const tokenObj = JSON.parse(window.atob(base64));

    return tokenObj;
  }

  async function calculateExpireTimeout (tokenObj: { iat: number; exp: number }): Promise<number|null> {
    if (!tokenObj) return null;
    const expireTimeout = new Date(tokenObj.exp * 1000);
    const issuedAt = new Date(tokenObj.iat * 1000);
    const minutesDiff = (expireTimeout.getTime() - issuedAt.getTime()) / 60000;
    const millisecondsDiff = minutesDiff * 60 * 1000;
    const millisecondsBeforeExpires = minutesDiff > 2 ? 120000 : 5;

    return millisecondsDiff - millisecondsBeforeExpires;
  }

  function setTokenRefreshTimeout (func) {
    tokenRefreshTimeout.value = func;
  }

  async function startRefreshTimeout () {
    let tokenObj: { iat: number; exp: number };

    try {
      tokenObj = await parseTokenToObject(token.value);
    } catch (ex) {
      if (ehrName.value.toLowerCase() === "cerner" && token.value && tokenExpiresAt.value && tokenIssuedAt.value) {
        tokenObj = { iat: tokenIssuedAt.value, exp: tokenExpiresAt.value };
      } else {
        throw ex;
      }
    }

    const millis = await calculateExpireTimeout(tokenObj);

    if (tokenRefreshTimeout.value != null) clearTimeout(tokenRefreshTimeout.value);

    if (!millis) {
      console.error("Token refresh couldn't be set. Token not found.");
      return;
    }

    setTokenRefreshTimeout(setTimeout(async () => {
      const tokenData = await AuthService.refreshToken();
      setTokenData(
        {
          BearerToken: tokenData.accessToken,
          BearerTokenIssuedAt: tokenData.bearerTokenIssuedAt,
          BearerTokenExpiresAt: tokenData.bearerTokenExpiresAt,
        }
      );
      startRefreshTimeout();
    }, millis));
  }

  async function expireToken () {
    if (getTimerId.value) {
      clearTimeout(getTimerId.value);
    }
    isTokenExpired.value = true;
    await AuthService.expireToken();
  }

  return { getEhrName, getTokenStatus, getToken, getStateid, getTimerId, getIsDialogVisible, setIsDialogVisible, setEhrName, setTokenData, setStateId, parseTokenToObject, calculateExpireTimeout, setTokenRefreshTimeout, startRefreshTimeout, expireToken };
});
