
import { defineComponent, ref, computed, WritableComputedRef, watch } from "vue";
import { useEducationCartStore } from "@/store/modules/EducationCart/educationCart";
import { useUserStore } from "@/store/modules/User/user";
import { useUIConfigurationStore } from "@/store/modules/UIConfiguration/uiConfiguration";

export default defineComponent({
  name: "ContentToolbar",
  props: {
    foldersView: Boolean,
    contentNumber: Number,
  },

  setup () {
    const UserModule = useUserStore();
    const CartModule = useEducationCartStore();
    const UIConfigurationModule = useUIConfigurationStore();
    const userDisplaySettings = computed(() => UserModule.getDisplaySettings);

    const changeCardsView = (grid: boolean) => {
      if (grid) {
        UserModule.updateSearchLayoutType(true);
      } else {
        UserModule.updateSearchLayoutType(false);
      }
    };

    const avsDefault = ref(UserModule.getDisplaySettings.avsCheckbox?.isSelectedByDefault);

    const avsSwitch: WritableComputedRef<boolean> = computed({
      get (): boolean {
        return CartModule.educationCart.AddToAvs ?? avsDefault.value;
      },
      set (newValue: boolean): void {
        CartModule.updateCart({ settingName: "AddToAvs", value: newValue });
      },
    });

    watch(avsSwitch, (newValue) => {
      CartModule.updateCart({ settingName: "AddToAvs", value: newValue });
    });

    const showAvsCheckbox = ref(UIConfigurationModule.getUIConfiguration.ShowAvsCheckbox);

    const isGridView = computed(function () {
      return UserModule.isGridView;
    });

    const afterVisitLabel = computed(function () {
      return avsSwitch.value
        ? `${UserModule.getDisplaySettings.avsCheckbox?.label ?? "Add to Avs"} ON`
        : `${UserModule.getDisplaySettings.avsCheckbox?.label ?? "Add to Avs"} OFF`;
    });
    const afterVisitTooltip = computed(function () {
      return avsSwitch.value
        ? `${UserModule.getDisplaySettings.avsCheckbox?.label ?? "Add to Avs"} ON`
        : `${UserModule.getDisplaySettings.avsCheckbox?.label ?? "Add to Avs"} OFF`;
    });

    const isPrintMenuOpened = ref(false);
    const selectedPrintSize: WritableComputedRef<number> = computed({
      get (): number {
        return CartModule.getPrintSize - 1;
      },
      set (newValue: number): void {
        CartModule.updateCart({ settingName: "PrintSize", value: newValue + 1 });
      },
    });
    const printListIitems = [
      { text: "XSmall", fontStyle: "caption" },
      { text: "Small", fontStyle: "body3" },
      { text: "Medium", fontStyle: "body2" },
      { text: "Large", fontStyle: "body1" },
      { text: "XLarge", fontStyle: "headline6" },
    ];

    const personalization: WritableComputedRef<boolean> = computed({
      get (): boolean {
        return CartModule.getPersonalization;
      },
      set (newValue: boolean): void {
        CartModule.updateCart({ settingName: "AddPatientName", value: newValue });
      },
    });
    const personalizationTooltip = computed(function () {
      return personalization.value
        ? `${UserModule.getDisplaySettings.labels?.addPatientNameToolTip ?? "Personalization"} ON`
        : `${UserModule.getDisplaySettings.labels?.addPatientNameToolTip ?? "Personalization"} OFF`;
    });
    const togglePersonalization = () => {
      personalization.value = !personalization.value;
    };

    const transcriptShare: WritableComputedRef<boolean> = computed({
      get (): boolean {
        return CartModule.getVideoTranscript;
      },
      set (newValue: boolean): void {
        CartModule.updateCart({ settingName: "VideoTranscript", value: newValue });
      },
    });
    const transcriptShareTooltip = computed(function () {
      return transcriptShare.value
        ? `${UserModule.getDisplaySettings.labels?.addVideoTranscriptTooltip ?? "Transcript Share"} ON`
        : `${UserModule.getDisplaySettings.labels?.addVideoTranscriptTooltip ?? "Transcript Share"} OFF`;
    });
    const toggleTranscriptShare = () => {
      transcriptShare.value = !transcriptShare.value;
    };

    return {
      changeCardsView,
      avsSwitch,
      showAvsCheckbox,
      afterVisitLabel,
      afterVisitTooltip,
      selectedPrintSize,
      isGridView,
      isPrintMenuOpened,
      printListIitems,
      personalization,
      personalizationTooltip,
      togglePersonalization,
      transcriptShare,
      transcriptShareTooltip,
      toggleTranscriptShare,
      userDisplaySettings,
    };
  },
});
