import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { Taxonomy } from "../../../models/taxonomy/taxonomy";

function compare (a, b) {
  const taxonomyAName = a.Taxonomy.DisplayName.toUpperCase();
  const taxonomyBName = b.Taxonomy.DisplayName.toUpperCase();
  const taxonomyAEncounter = a.Taxonomy.IsCurrentEncounter;
  const taxonomyBEncounter = b.Taxonomy.IsCurrentEncounter;
  const taxonomyAPrincipal = a.Taxonomy.IsPrincipalProblem;
  const taxonomyBPrincipal = b.Taxonomy.IsPrincipalProblem;

  if (taxonomyAPrincipal !== taxonomyBPrincipal) {
    return taxonomyBPrincipal - taxonomyAPrincipal;
  } else if (taxonomyAEncounter !== taxonomyBEncounter) {
    return taxonomyBEncounter - taxonomyAEncounter;
  } else {
    return taxonomyAName.localeCompare(taxonomyBName);
  }
}

export const useTaxonomiesStore = defineStore("Taxonomies", () => {
  const taxonomies = ref<Taxonomy[]>([] as Taxonomy[]);

  const getTaxonomies = computed(() => {
    if (taxonomies.value) {
      return taxonomies.value.sort(compare);
    } else {
      return [];
    }
  });

  const getTaxonomyByCode = (taxonomyCode: string): Taxonomy | undefined => {
    return taxonomies.value?.find(x => x.Taxonomy.Codings[0].Code === taxonomyCode);
  };

  function setTaxonomies (newTaxonomies: Taxonomy[]) {
    if (!newTaxonomies) newTaxonomies = [];
    // if there is already a taxonomy, add the new ones to the existing ones
    // if there is nothing in the main taxonomy array, set the new ones
    // otherwise, don't do anything
    if (taxonomies.value.length > 0 && newTaxonomies.length > 0) {
      // check if taxonomy doesn't exist in the main array before adding it
      newTaxonomies.forEach(taxonomy => {
        if (!taxonomies.value.find(x => x.Taxonomy.Code === taxonomy.Taxonomy.Code)) {
          taxonomies.value.push(taxonomy);
        }
      });
    } else if (taxonomies.value.length === 0) {
      taxonomies.value = newTaxonomies;
    }
  }

  function clearTaxonomies () {
    taxonomies.value = [];
  }

  return { taxonomies, getTaxonomies, getTaxonomyByCode, setTaxonomies, clearTaxonomies };
});
