import { defineStore } from "pinia";
import { ContentType } from "@/models/contentType/contentType";
import { ref, computed } from "vue";
export const useContentTypesStore = defineStore("ContentTypesModule", () => {
  const contentTypes = ref<ContentType[]>([]);

  const getContentTypes = computed(() => contentTypes.value);

  const getContentTypesNames = computed(() => contentTypes.value.map((type: ContentType) => type.ContentTypeName));

  const getContentTypeIdByContentTypeName = computed(() => (contentTypeName: string): number => {
    const contentType = contentTypes.value.find(contentType => contentType.ContentTypeName === contentTypeName);
    return contentType ? contentType.ContentTypeId : -1;
  });

  const getContentTypeNameById = computed(() => (contentId: string | number): string => {
    const contentType = contentTypes.value.find(contentType => contentType.ContentTypeId === Number(contentId));
    return contentType ? contentType.ContentTypeName : "";
  });

  const getContentTypeIds = computed(() => contentTypes.value.map(type => type.ContentTypeId));

  function setContentTypes (newContentTypes: ContentType[]): void {
    contentTypes.value = newContentTypes;
  }

  return { contentTypes, getContentTypes, getContentTypesNames, getContentTypeIdByContentTypeName, getContentTypeNameById, getContentTypeIds, setContentTypes };
});
