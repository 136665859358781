import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "./assets/style.css";
import { DirectiveBinding } from "vue/types/options";
import cssVars from "css-vars-ponyfill";
import { createPinia, PiniaVuePlugin } from "pinia";

// ponyfill for css custom properties for ie 11
cssVars();
Vue.config.productionTip = false;

const update = (el: HTMLElement,
  binding: DirectiveBinding) => { el.style.visibility = (binding.value) ? "hidden" : ""; };

/**
 * Hides an HTML element, keeping the space it would have used if it were visible (css: Visibility)
 * based on: https://www.ryansouthgate.com/2020/01/30/vue-js-v-hide-element-whilst-keeping-occupied-space/
 */
Vue.directive("hide", {
  // Run on initialisation (first render) of the directive on the element
  bind: update,
  // Run on subsequent updates to the value supplied to the directive
  update: update,
});

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
Vue.use(VueRouter);
new Vue({
  pinia,
  router,
  vuetify,
  render: h => h(App),
}).$mount("#app");
