import { ref, computed } from "vue";
import router from "@/router/index";

const errorPage = ref(false);
const _correlationId = ref("");
const correlationIdKey = "CorrelationId";

const correlationId = computed(() => {
  if (!_correlationId.value) {
    _correlationId.value = localStorage.getItem(correlationIdKey) ?? "";
  }
  return _correlationId.value;
});

const showErrorPage = (errorMessage: string, errorId = "") => {
  errorPage.value = true;
  errorId = errorId === "" ? correlationId.value : errorId;
  console.log("error from errorpage comp");
  router.push({
    name: "error",
    params: { errorMessage: errorMessage, errorId: errorId },
  });
};

const useErrorPage = () => {
  return { showErrorPage, errorPage, correlationId };
};

export { useErrorPage };
