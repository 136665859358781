import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useSearchBarStore = defineStore("SearchBar", () => {
  const searchTerm = ref("");
  const selectedSearchTerm = ref<string|undefined>(undefined);
  const typedTerms = ref<string[]>([]);
  const isFilterOpen = ref(false);
  const searchResultsVisible = ref(false);
  const isSearching = ref(false);
  const searchTerms = ref<string[]>([]);
  const isReloadContent = ref(false);

  const getSearchTerms = computed(() => searchTerms.value);
  const getSelectedSearchTerm = computed(() => selectedSearchTerm.value);
  const getTypedTerms = computed(() => typedTerms.value);

  function updateSearchTerms (terms: string[]) {
    searchTerms.value = terms;
  }

  function updateSelectedSearchTerm (terms: string | undefined) {
    selectedSearchTerm.value = terms;
  }

  function updateTypedTerms (terms: string[]) {
    typedTerms.value = terms;
  }

  function setReloadContent (value: boolean) {
    isReloadContent.value = value;
  }

  function doSearch (value: boolean) {
    isSearching.value = value;
  }

  function updateSearchTerm (value: string) {
    searchTerm.value = value;
  }

  function updateFilterStatus (value: boolean) {
    isFilterOpen.value = value;
  }

  function updateResultsVisible (value: boolean) {
    searchResultsVisible.value = value;
  }

  return { searchTerm, selectedSearchTerm, typedTerms, isFilterOpen, searchResultsVisible, isSearching, searchTerms, isReloadContent, getSearchTerms, getSelectedSearchTerm, getTypedTerms, updateSearchTerms, updateSelectedSearchTerm, updateTypedTerms, setReloadContent, doSearch, updateSearchTerm, updateFilterStatus, updateResultsVisible };
});
