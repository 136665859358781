
import { defineComponent, onMounted, ref, reactive, computed, watch } from "vue";
import { useRoute } from "vue-router/composables";
import { useVuetify } from "./utility/useVuetify";
import cssVars from "css-vars-ponyfill";
import router from "./router/index";
import { useAuthStore } from "./store/modules/Auth/auth";
import { useUserStore } from "./store/modules/User/user";
import { usePrintShareStore } from "./store/modules/PrintShare/printShare";
import { useTaxonomiesStore } from "./store/modules/Taxonomies/taxonomies";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import SkeletonLoader from "./components/SkeletonLoader.vue";
import RecommendedHeader from "./components/RecommendedHeader.vue";
import { useSearchBarStore } from "./store/modules/SearchBar/searchBar";
import PolyfillClosest from "@/utility/polyfillClosest";
import { usePurgeApp } from "@/composables/purge";
import activateNewRelic from "./utility/setUpNewRelic";
import { useAuthentication } from "@/composables/authentication";
import { useInitialAuthenticationStates } from "@/composables/initialAuthenticationStates";
import { useErrorPage } from "@/composables/errorPage";
import { useHelpDialog } from "@/composables/helpDialog";
const EmailDialog = () => import("./components/EmailDialog.vue");
const EmailNotificationDialog = () => import("./components/EmailNotificationDialog.vue");
const HelpDialog = () => import("./components/HelpDialog.vue");
const PrePublishDialog = () => import("./components/PrePublishDialog.vue");
const SessionExpirationDialog = () => import("./components/SessionExpirationDialog.vue");
const Snackbar = () => import("./components/Snackbar.vue");

export default defineComponent({
  name: "App",
  components: {
    EmailDialog,
    EmailNotificationDialog,
    Footer,
    Header,
    HelpDialog,
    PrePublishDialog,
    RecommendedHeader,
    SessionExpirationDialog,
    SkeletonLoader,
    Snackbar,
  },
  setup () {
    const PrintShare = usePrintShareStore();
    const AuthModule = useAuthStore();
    const UserModule = useUserStore();
    const TaxonomiesModule = useTaxonomiesStore();
    const SearchBar = useSearchBarStore();
    let endpoint = "";
    const loading = ref(true);
    const state = ref("");

    const tenantId = ref("");

    // Set the correlationId for this launch
    const correlationId = new Date()
      .toISOString()
      .split("T")[1]
      .replaceAll(":", "")
      .replace(".", "")
      .replace("Z", "");
    localStorage.setItem("CorrelationId", correlationId);

    // import whats needed from composables
    const { isHelpLoading, isHelpDialogVisible, openHelpDialog } = useHelpDialog();
    const { checkAuthentication, refreshed, refreshState } = useAuthentication();
    const { unLoading, purgeApp } = usePurgeApp();
    const { samlDiagnostics } = useInitialAuthenticationStates();
    const { errorPage, showErrorPage } = useErrorPage();
    const vuetify = useVuetify();
    const refreshTheState = () => {
      TaxonomiesModule.clearTaxonomies();
      refreshState(loading, endpoint, tenantId);
    };

    const isEmailDialogVisible = reactive({ isVisible: false });
    const openEmailDialog = async () => {
      isEmailDialogVisible.isVisible = true;
    };

    const diagnosticsOpener = (e: KeyboardEvent) => {
      if (e.altKey && e.key === "D") {
        router.push({ path: "/diagnostics", query: { state: state.value } });
      }
    };

    onMounted(() => {
      PolyfillClosest();
      /*
        if the backend returned with an error message in the query parameters
        before authentication has occured, show the error page
      */
      if (router.currentRoute.query.errorMessage) {
        const error = router.currentRoute.query.errorMessage as string;
        const errorId = router.currentRoute.query.errorId as string;
        loading.value = false;
        showErrorPage(error, errorId);
        return;
      }

      if (!AuthModule.getToken) {
        const query = router.currentRoute.query;
        const ehrName = query.ehrName as string;
        tenantId.value = query.tenantId as string;
        state.value = query.state as string;
        endpoint = query.endpoint as string;
        activateNewRelic(query);

        // check the authentication
        checkAuthentication(ehrName, query, loading, tenantId, endpoint);

        //  add a global event to open the diagnostics view from a "Alt + Shift + D" command
        window.addEventListener("keydown", diagnosticsOpener);
        window.addEventListener("beforeunload", purgeApp);
      }
    });

    const routeInfo = useRoute();

    /**
     * returns true if the route is the patientencounter route, or if we are still on the
     * "index" page, which happens before being "redirected" to patientencounter
     */
    const isRecommendedView = computed(() => {
      return routeInfo.name === "PatientEncounter" || routeInfo.path === "/index.html";
    });

    // will return: PatientEncounter,Folder,Favorites,History
    const currentRouteName = computed(() => {
      return routeInfo.name;
    });
    // set true to flag for reloading content in patientEncounter view
    const backToHomePage = () => {
      const isPatientEncounterView = currentRouteName.value?.toLowerCase() === "patientencounter";
      const isSearchBarInUse = SearchBar.searchResultsVisible === true;
      if (isPatientEncounterView && isSearchBarInUse) {
        SearchBar.setReloadContent(true);
      } else {
        router.push({ path: "/patientEncounter" });
      }
    };

    const isEmailNotificationDialogVisible = computed(() => PrintShare.getShowEmailDialog);
    const isPrePublishDialogDialogVisible = computed(() => PrintShare.getShowPrePublishDialog);

    watch(() => UserModule.getUserSettings, () => {
      if (vuetify) {
        vuetify.theme.dark = UserModule.user.userSettings.DarkModeEnabled ?? false;
        // cssVars handles css custom properties in IE 11.
        cssVars();
      }
    }, { deep: true });
    return {
      loading,
      unLoading,
      isHelpLoading,
      isHelpDialogVisible,
      openHelpDialog,
      isEmailDialogVisible,
      isEmailNotificationDialogVisible,
      isPrePublishDialogDialogVisible,
      openEmailDialog,
      refreshTheState,
      backToHomePage,
      samlDiagnostics,
      errorPage,
      isRecommendedView,
      refreshed,
    };
  },
});
