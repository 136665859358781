<template>
  <v-badge
    :data-qaid="`itemReadBadge_${item.ContentTypeId}_${item.ContentId}`"
    inline
    dot
    left
    bordered
    :color="item.PercentViewed > 0 ? 'success' : $vuetify.theme.dark ? 'outlines lighten-2' : 'outlines'"
    style="white-space: nowrap;"
  >
    <p
      v-if="item.PrescribedType === 'PrintedOnly'"
      class="body3 font-weight-bold mb-0 ml-3"
      :data-qaid="`text_itemPercentViewedZero_${item.ContentTypeId}_${item.ContentId}`">
      N/A
    </p>
    <p
      v-else-if="item.PercentViewed === 0"
      class="body3 font-weight-bold mb-0 ml-3"
      :data-qaid="`text_itemPercentViewedZero_${item.ContentTypeId}_${item.ContentId}`"
    >Not Viewed
    </p>
    <p
      v-else-if="item.PercentViewed === 100"
      :data-qaid="`text_itemPercentViewed100_${item.ContentTypeId}_${item.ContentId}`"
      class="body3 mb-0 ml-3"
    >
      Viewed
    </p>
    <p
      v-else-if="item.PercentViewed <= 100 && item.PercentViewed > 0"
      :data-qaid="`text_itemPercentViewedPercentage_${item.ContentTypeId}_${item.ContentId}_${item.PercentViewed}`"
      class="body3 mb-0 ml-3"
    >
      Viewed {{ item.PercentViewed }}%
    </p>
  </v-badge>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
});
</script>
