import { ref, reactive } from "vue";

import HelpService from "@/services/helpService";
import { useHelpStore } from "@/store/modules/Help/help";

const useHelpDialog = () => {
  const isHelpLoading = ref(false);
  const isHelpDialogVisible = reactive({ isVisible: false });
  const HelpModule = useHelpStore();
  const getHelpContent = () => {
    if (HelpModule.getContent) {
      return HelpModule.getContent;
    }
    return HelpService.getHelpContent().then(res => {
      HelpModule.setHelpContent(res);
    });
  };

  const openHelpDialog = async () => {
    isHelpDialogVisible.isVisible = true;
    isHelpLoading.value = true;
    await getHelpContent();
    isHelpLoading.value = false;
  };
  return { isHelpLoading, isHelpDialogVisible, openHelpDialog };
};

export { useHelpDialog };
