import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useIdleTimeStore = defineStore("IdleTime", () => {
  const idleTimeBeforeWarningMilliseconds = ref(10000);
  const warningTimeSeconds = ref(20);
  const minutesOfWarnignRemaining = ref(60);
  const secondsOfWarningRemaining = ref(42);
  const showDialog = ref(false);
  const timerId = ref(0);

  const getShowDialog = computed(() => {
    return true;
  });

  const getWarningTimeSeconds = computed(() => warningTimeSeconds.value);

  function setTimerId (id: number) {
    timerId.value = id;
  }

  function setShowDialog (flag: boolean) {
    showDialog.value = flag;
  }
  function startIdleWarning () {
    setTimerId(0);
    setShowDialog(true);
  }

  function addEventListeners (func) {
    window.addEventListener("click", func, false);
    window.addEventListener("keydown", func, false);
    window.addEventListener("scroll", func, false);
    window.addEventListener("wheel", func, false);
  }

  function onActivity () {
    if (!showDialog.value) {
      if (timerId.value) {
        clearTimeout(timerId.value);
        setTimerId(0);
      }
      if (!timerId.value) {
        const id = setTimeout(startIdleWarning, idleTimeBeforeWarningMilliseconds.value);
        setTimerId(id);
        addEventListeners(onActivity);
      }
    }
  }

  function setIdleTimeBeforeWarningMilliseconds (numMinutes: number) {
    idleTimeBeforeWarningMilliseconds.value = numMinutes;
  }

  function setWarningTimeSeconds (numSeconds: number) {
    warningTimeSeconds.value = numSeconds;
  }

  function watchIdleTime ({ expirationTimeout, expirationNoticeWindow }) {
    setIdleTimeBeforeWarningMilliseconds((expirationTimeout - expirationNoticeWindow) * 60 * 1000);
    setWarningTimeSeconds(expirationNoticeWindow * 60);
    if (!timerId.value) {
      const id = setTimeout(startIdleWarning, idleTimeBeforeWarningMilliseconds.value);
      setTimerId(id);
      addEventListeners(onActivity);
    }
  }

  function RestartIdleWatch () {
    setShowDialog(false);
    if (!timerId.value) {
      const id = setTimeout(startIdleWarning, idleTimeBeforeWarningMilliseconds.value);
      setTimerId(id);
      addEventListeners(onActivity);
    }
  }

  return { RestartIdleWatch, idleTimeBeforeWarningMilliseconds, warningTimeSeconds, minutesOfWarnignRemaining, secondsOfWarningRemaining, showDialog, timerId, getShowDialog, getWarningTimeSeconds, setTimerId, setShowDialog, startIdleWarning, addEventListeners, onActivity, setIdleTimeBeforeWarningMilliseconds, setWarningTimeSeconds, watchIdleTime };
});
