
import { defineComponent, ref, computed } from "vue";
import convertDate from "../utility/convertDate";
import { useContentStore } from "@/store/modules/Content/content";
import getIndicator from "../utility/getIndicator";
import CustomIcon from "./CustomIcon.vue";
import PrintMenu from "./PrintMenu.vue";
import { useUIConfigurationStore } from "@/store/modules/UIConfiguration/uiConfiguration";
import { useUserStore } from "@/store/modules/User/user";
import isEdited from "@/utility/isEdited";
import printShareFunctionality from "@/composables/PrintOrShare";
import PrescribedType from "@/composables/prescribedType";
import FavoriteIcon from "./icons/FavoriteIcon.vue";
import UnFavoriteIcon from "./icons/UnFavoriteIcon.vue";
import ShareIcon from "./icons/ShareIcon.vue";
import PreviewModalFunctionality from "@/composables/PreviewModalFunctionality";
const LanguageSelector = () => import("./LanguageSelector.vue");

export default defineComponent({
  name: "GridViewCard",
  components: { CustomIcon, FavoriteIcon, UnFavoriteIcon, ShareIcon, LanguageSelector, PrintMenu },
  props: ["material", "index"],
  setup (props, { emit }) {
    const ContentModule = useContentStore();
    const UserModule = useUserStore();
    const UiConfigurationModule = useUIConfigurationStore();
    const disabled = ref(false);
    const isGoingToFavoriteService = ref(false);
    const { isOpenInPreview } = PreviewModalFunctionality(props);
    const { isSharing, isPrinting, printOrShareMaterials } = printShareFunctionality();
    const { getMaterialPublishedHistory, getPrescribedType } = PrescribedType(props);

    const userDisplaySettings = computed(() => UserModule.getDisplaySettings);
    const uiConfiguration = computed(() => UiConfigurationModule.uiConfiguration);

    const showPrintButton = computed((): boolean => {
      return UserModule.getDisplaySettings.printButton?.visibility === 1;
    });

    const isSelected = computed((): boolean => {
      const selectedMaterials = ContentModule.getSelectedMaterials;
      return !!selectedMaterials[props.material.FrontEndId];
    });

    const isInFavorites = computed((): boolean => {
      const favorites = ContentModule.getFavorites;
      return !!favorites[props.material.FrontEndId];
    });

    const materialPublishedHistory = getMaterialPublishedHistory();
    const prescribedType = getPrescribedType(materialPublishedHistory);

    const toggleFavorite = async (contentTypeId, isFavorite) => {
      disabled.value = isGoingToFavoriteService.value = true;
      await ContentModule.toggleFavorite({ material: props.material, contentTypeId, isFavorite });
      disabled.value = isGoingToFavoriteService.value = false;
    };

    const toggleSelected = () => {
      let cartLimitExceeded = false;
      if (UserModule.getDisplaySettings.cartItemLimit != null) {
        if (Object.keys(ContentModule.getSelectedMaterials).length >= UserModule.getDisplaySettings.cartItemLimit) {
          cartLimitExceeded = true;
        }
      }
      if (!isSelected.value && cartLimitExceeded) {
        emit("onCartLimitExceeded");
      } else {
        isSelected.value
          ? ContentModule.removeFromSelected(props.material)
          : ContentModule.addToSelected(props.material);

        props.material.IsSelected = !props.material.IsSelected;
      }
    };

    const openPreview = (material) => {
      emit("onOpenPreview", { material, index: props.index });
    };

    const showPrintMenuTooltip = ref(false);

    return {
      convertDate,
      toggleFavorite,
      getIndicator,
      disabled,
      isEdited,
      isGoingToFavoriteService,
      isSelected,
      isSharing,
      isPrinting,
      isInFavorites,
      materialPublishedHistory,
      toggleSelected,
      openPreview,
      prescribedType,
      printOrShareMaterials,
      uiConfiguration,
      userDisplaySettings,
      showPrintButton,
      showPrintMenuTooltip,
      isOpenInPreview,
    };
  },
});
