
import { defineComponent, computed } from "vue";
import { useHelpStore } from "@/store/modules/Help/help";

export default defineComponent({
  name: "HelpDialog",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  setup () {
    const HelpModule = useHelpStore();
    const helpContent = computed(() => {
      return HelpModule.getContent;
    });

    return { helpContent };
  },
});
