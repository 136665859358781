
import { defineComponent, ref, computed, watch, reactive, WritableComputedRef } from "vue";
import RecommendedHeader from "../components/RecommendedHeader.vue";
import ContentToolbar from "../components/ContentToolbar.vue";
import ContentContainer from "../components/ContentContainer.vue";
import SideFilter from "../components/SideFilter.vue";
import SkeletonLoader from "../components/SkeletonLoader.vue";

import ContentService from "../services/contentService";
import SearchService from "../services/searchService";
import FavoritesService from "../services/favoritesService";
import PublishedHistory from "../services/publishedHistoryService";
import EditContentService from "../services/editContentService";
import formatText from "../utility/formatText";
import filterContent from "../utility/filterContent";

import { useUserStore } from "@/store/modules/User/user";
import { useTaxonomiesStore } from "@/store/modules/Taxonomies/taxonomies";
import { useContentStore } from "@/store/modules/Content/content";
import { useContentTypesStore } from "@/store/modules/ContentTypes/contentTypes";
import { useTableSettingsStore } from "@/store/modules/TableSettings/tableSettings";
import { useSearchBarStore } from "@/store/modules/SearchBar/searchBar";
import { useUIConfigurationStore } from "@/store/modules/UIConfiguration/uiConfiguration";

import { CategoryTab } from "../models/user/user";
import { Content, ContentList, createEmptyContent } from "../models/content/content";
import { Taxonomy, SearchRequest, ContentSearchRequest } from "../models/taxonomy/taxonomy";
import { Gender } from "../models/patient/patient";

export default defineComponent({
  name: "PatientEncounter",
  components: {
    RecommendedHeader,
    ContentToolbar,
    ContentContainer,
    SideFilter,
    SkeletonLoader,
  },
  props: {
    tenantId: {
      default: "",
      type: String,
    },
    refreshed: {
      default: false,
      type: Boolean,
    },
  },
  setup (props, { emit }) {
    const ContentModule = useContentStore();
    const TableSettingsModule = useTableSettingsStore();
    const TaxonomiesModule = useTaxonomiesStore();
    const UserModule = useUserStore();
    const contentTypeStore = useContentTypesStore();
    const UIConfigurationModule = useUIConfigurationStore();
    const SearchModule = useSearchBarStore();
    const isFirst = ref(true);
    const areSearchResultsVisible = computed(() => SearchModule.searchResultsVisible);
    const contentLoading = ref(false);
    const primaryTaxonomyLoading = ref(false);
    const taxonomiesLoading = ref(false);
    const searchTerm = computed(() => SearchModule.searchTerm);
    const doSearch = computed(() => SearchModule.isSearching);
    const isReloadContent = computed(() => SearchModule.isReloadContent);
    const selectedAgeGroupIds = ref([]);
    const selectedGender = ref({} as Gender);
    const selectedMediaType = ref(0);
    const activetab = ref(-1);
    const content = ref<ContentList[]>([]);
    const selectedTaxonomy = ref(-1);
    const taxonomyContentLoading = ref({});
    const taxonomiesWithContent = ref({});
    const pageSize = computed(() => TableSettingsModule.rowsPerPage);
    const pageNumber = computed(() => TableSettingsModule.pageNumber);
    const newContent = ref(false);
    const taxonomyTitleCount = ref(0);
    const contentContainer = ref();
    const showTaxonomyCounts = computed(() => UIConfigurationModule.uiConfiguration.ShowTaxonomyCounts);
    const otherTaxonomyCountsLoading = ref(false);
    const isFilterOpen: WritableComputedRef<boolean> = computed({
      get (): boolean {
        return SearchModule.isFilterOpen;
      },
      set (newValue: boolean): void {
        SearchModule.updateFilterStatus(newValue);
      },
    });
    const filteredContent = ref<ContentList[] | ContentList>([]);
    const filter = reactive({
      sortBy: "most-relevant",
      selectedContentTypes: [],
      selectedContentTypesIds: [],
    });

    const selectedCategory = ref<CategoryTab>({ id: -1, label: "", ordinal: -1 });

    const categoryTabs = computed(() => {
      return UserModule.getCategoryTabs.map(category => {
        return {
          id: category.id,
          label: category.label,
          ordinal: category.ordinal,
        } as CategoryTab;
      });
    });

    const taxonomiesPerSelectedCategory = computed((): Taxonomy[] => {
      const taxonomies = TaxonomiesModule.getTaxonomies.filter(
        taxonomyObj => taxonomyObj.TaxonomyCategory === +selectedCategory.value.id
      );
      return taxonomies;
    });

    const indexedTaxonomies = computed(() => {
      const taxonomies = taxonomiesPerSelectedCategory.value;
      return taxonomies?.map((item, index) => ({
        ...item,
        index,
      }));
    });

    const firstCategory = computed(() => {
      return UserModule.getFirstCategory;
    });

    const getFavorites = async () => {
      return await FavoritesService.getFavorites().then(res => {
        ContentModule.updateFavorites(res);
      });
    };

    const getEdited = () => {
      return EditContentService.getEditContent().then(res => {
        ContentModule.updateEdited(res);
      });
    };

    const setSelectedCategoryTab = (id: number, label: string) => {
      selectedCategory.value = {
        id: id,
        label: label,
      } as CategoryTab;
      SearchModule.updateResultsVisible(false);
      SearchModule.updateSelectedSearchTerm(undefined);
    };

    // We are setting the entire value of the taxonomyContentLoading object to force the UI to update.
    // There is probably a better way to ensure reactive UI state for this use case, so we should update
    // this procedure in the future.
    const flagContentLoading = (taxonomyCode: string, loading: boolean, promise?: Promise<Content>|undefined) => {
      const newTaxonomyLoadingTracker = taxonomyContentLoading.value;
      newTaxonomyLoadingTracker[taxonomyCode] = {
        loading: loading,
        promise: promise,
      };
      taxonomyContentLoading.value = { ...newTaxonomyLoadingTracker };
    };

    // We are setting the entire value of the taxonomiesWithContent object to force the UI to update.
    // There is probably a better way to ensure reactive UI state for this use case, so we should update
    // this procedure in the future.
    const trackLoadedContent = (taxonomyCode: string, loaded: boolean) => {
      const newTaxonomiesWithContent = taxonomiesWithContent.value;
      newTaxonomiesWithContent[taxonomyCode] = loaded;
      taxonomiesWithContent.value = { ...newTaxonomiesWithContent };
    };

    const getCurrentSearchAndFiltersValues = (forContentCounts = false) => {
      const inputsForm: SearchRequest = {
        SearchTerm: forContentCounts ? null : searchTerm.value,
        AgeGroupIds: selectedAgeGroupIds.value,
        Gender: selectedGender.value,
        MediaType: selectedMediaType.value,
        ContentTypesFilter: filter.selectedContentTypesIds ?? [],
      };
      return inputsForm;
    };

    const addSearchFiltersToContentRequest = (contentSearchRequest, forContentCounts = false) => {
      const currentSearchFiltersValues = getCurrentSearchAndFiltersValues(forContentCounts);
      contentSearchRequest.SearchFilter = { ...currentSearchFiltersValues };
      contentSearchRequest.ContentTypeIds = filter.selectedContentTypesIds ?? [];
    };

    const addContentTypeIdsToContentRequest = (contentSearchRequest, contentTypeIds) => {
      if (contentTypeIds.length) {
        contentSearchRequest.ContentTypeIds = contentTypeIds;
      }
    };

    const addPaginationToContentRequest = (contentSearchRequest, singleItem = false) => {
      const pageNumber = singleItem ? 1 : TableSettingsModule.pageNumber;
      const pageSize = singleItem ? 1 : TableSettingsModule.rowsPerPage;
      if (pageNumber > 0 && pageSize > 0) {
        const pagination = { RequestedPage: pageNumber, RowsPerPage: pageSize };
        contentSearchRequest.SearchFilter = { ...contentSearchRequest.SearchFilter, ...pagination };
      }
    };
    // get first code of codings as key element for future matches
    const getFirstCodingsCode = (taxonomy: Taxonomy) => {
      return taxonomy.Taxonomy.Codings[0].Code;
    };

    const getContentCountsForOtherTaxonomies = async () => {
      if (!showTaxonomyCounts.value) return;
      const searchRequests: ContentSearchRequest[] = [];
      const contentTypeIds = filter.selectedContentTypesIds;
      if (contentTypeIds.length === 0) {
        ContentModule.clearTitleCounts();
        return;
      }
      for (let i = 0; i < taxonomiesPerSelectedCategory.value.length; i++) {
        if (i === selectedTaxonomy.value) {
          continue;
        }
        const taxonomyCode = getFirstCodingsCode(taxonomiesPerSelectedCategory.value[i]);
        const taxonomy = TaxonomiesModule.getTaxonomyByCode(taxonomyCode);
        const contentRequest: ContentSearchRequest = {
          Taxonomy: taxonomy?.Taxonomy,
          TaxonomyCategory: taxonomy?.TaxonomyCategory,
        };
        addSearchFiltersToContentRequest(contentRequest, true);
        addContentTypeIdsToContentRequest(contentRequest, contentTypeIds);
        addPaginationToContentRequest(contentRequest, true);
        searchRequests.push(contentRequest);
      }
      if (searchRequests.length === 0) {
        return;
      }
      otherTaxonomyCountsLoading.value = true;
      const otherTaxonomyContentInfo = await ContentService.getContentCountsForTaxonomy(searchRequests).catch(e => console.error(e));
      otherTaxonomyCountsLoading.value = false;
      if (otherTaxonomyContentInfo) {
        for (let i = 0; i < otherTaxonomyContentInfo.length; i++) {
          ContentModule.pushTitleCounts(otherTaxonomyContentInfo[i]);
        }
      }
    };

    const getTaxonomyContentFromService = async (taxonomyCode: string, contentTypeIds: number[] = []): Promise<Content> => {
      // Get the Taxonomy for this code.
      const taxonomy = await TaxonomiesModule.getTaxonomyByCode(taxonomyCode);
      if (contentTypeIds.length === 0) {
        ContentModule.clearTitleCounts();
        return createEmptyContent();
      }
      // Build a ContentSearchRequest object from Taxonomy and Filters.
      const contentRequest: ContentSearchRequest = {
        Taxonomy: taxonomy?.Taxonomy,
        TaxonomyCategory: taxonomy?.TaxonomyCategory,
      };
      addSearchFiltersToContentRequest(contentRequest);
      addContentTypeIdsToContentRequest(contentRequest, contentTypeIds);
      addPaginationToContentRequest(contentRequest);

      // Send Request to service to get Content.
      const taxonomyContent = await ContentService.getSuggestedContent(contentRequest).catch(e => console.error(e));
      const _filteredContent = filterContent(filter.sortBy, filter.selectedContentTypes, taxonomyContent.ContentList);
      // Check to see that content was actually returned
      if (!taxonomyContent || !taxonomyContent.ContentList || taxonomyContent.ContentList.length < 1 || _filteredContent.length < 1) {
        trackLoadedContent(taxonomyCode, false);
      } else {
        trackLoadedContent(taxonomyCode, true);
      }
      ContentModule.pushContent(taxonomyContent);

      return taxonomyContent;
    };

    const getContentFromStore = (taxonomyCode: string): ContentList | undefined => {
      return (ContentModule.getContent.find((t: Content) =>
        t.Taxonomies[0].Code === taxonomyCode))?.ContentList;
    };

    const getPage = async (taxonomyCode: string, contentTypeIds: number[] = []): Promise<Content> => {
      contentLoading.value = true;
      const promiseFromContentRequest = getTaxonomyContentFromService(taxonomyCode, contentTypeIds);
      flagContentLoading(taxonomyCode, true, promiseFromContentRequest);
      const newContent = await promiseFromContentRequest;
      taxonomyTitleCount.value = ContentModule.getTaxonomyTitleCount(taxonomyCode);
      ContentModule.updateDisplayedContentCount(taxonomyTitleCount.value);
      flagContentLoading(taxonomyCode, false);
      contentLoading.value = false;
      return newContent;
    };

    const getContent = async (taxonomyCode: string, contentTypeIds: number[] = []): Promise<Content> => {
      taxonomyContentLoading.value[taxonomyCode] = taxonomyContentLoading.value[taxonomyCode] ?? { loading: false, promise: undefined };
      if (taxonomyContentLoading.value[taxonomyCode].loading) await taxonomyContentLoading.value[taxonomyCode].promise;

      // get the page we are looking for
      return getPage(taxonomyCode, contentTypeIds);
    };

    const getSelectedTaxonomyCode = () => {
      return taxonomiesPerSelectedCategory.value[selectedTaxonomy.value].Taxonomy.Codings[0].Code;
    };

    const setSelectedContent = (selectedContent) => {
      if (selectedContent) {
        content.value = selectedContent.ContentList;
      } else {
        content.value = [] as ContentList[];
      }
    };

    // watch search term prop, if it is not "" and not what it was before, fire off below
    const loadContentPerSearchTerm = (term) => {
      if (!term) {
        SearchModule.doSearch(false);
        return;
      }
      contentLoading.value = true;
      // set the category first to clear the screen
      selectedCategory.value = {} as CategoryTab;
      activetab.value = -1;
      const searchAndFiltersValues = getCurrentSearchAndFiltersValues();

      searchAndFiltersValues.RequestedPage = pageNumber.value;
      searchAndFiltersValues.RowsPerPage = pageSize.value;

      SearchService.getSearchContent(searchAndFiltersValues).then(res => {
        taxonomyTitleCount.value = res.TotalResults;
        // now set the content so it shows
        content.value = res.ContentList;
        ContentModule.updateSearchResults(res.ContentList);
        SearchModule.doSearch(false);
        SearchModule.updateResultsVisible(true);
        contentLoading.value = false;
      });
    };

    watch(doSearch, (newValue, oldValue) => {
      if (newValue && newValue !== oldValue) {
        // clear content due to filter changes
        ContentModule.clearContent();
        loadContentPerSearchTerm(searchTerm.value);
      }
    });

    const updateContentForTaxonomy = async () => {
      const taxonomyCode = getSelectedTaxonomyCode();
      const selected = await getContent(taxonomyCode, filter.selectedContentTypesIds);
      setSelectedContent(selected);
    };

    watch(pageNumber, (newVal, oldVal) => {
      if (newVal !== oldVal && !TableSettingsModule.viewingHistory && !TableSettingsModule.viewingFavorites) {
        if (!areSearchResultsVisible.value) {
          updateContentForTaxonomy();
        } else if (areSearchResultsVisible.value) {
          loadContentPerSearchTerm(searchTerm.value);
        }
      }
    });

    watch(pageSize, (newVal, oldVal) => {
      if (newVal !== oldVal && !TableSettingsModule.viewingHistory && !TableSettingsModule.viewingFavorites) {
        if (!areSearchResultsVisible.value) {
          const taxContent = ContentModule.content.find(item => item.Taxonomies[0].Code === getSelectedTaxonomyCode());
          if (taxContent && pageNumber.value === 1) {
            updateContentForTaxonomy();
          }
        } else if (areSearchResultsVisible.value && pageNumber.value === 1) {
          loadContentPerSearchTerm(searchTerm.value);
        }
      }
    });

    const getTaxonomies = (categories: CategoryTab[] = []) => {
      const categoryLabels = categories.map(category => category.label);
      ContentModule.clearContent();
      return ContentService.getTaxonomyResults(props.refreshed, categoryLabels).then(res => {
        TaxonomiesModule.setTaxonomies(res);
        emit("updateRefreshed");
      });
    };

    const getPublishedHistory = () => {
      PublishedHistory.getPublishedHistory().then(res => {
        ContentModule.setPublishedMaterials(res);
      });
    };

    const setSelectedTaxonomy = async (taxonomy: Taxonomy | null) => {
      // scroll content container to the top, first check if container is mounted
      if (contentContainer?.value?.$refs?.container) {
        contentContainer.value.$refs.container.scrollTop = 0;
      }

      if (!taxonomy) {
        selectedTaxonomy.value = -1;
        content.value = [] as ContentList[];
        return;
      }
      // get first coding element
      const taxonomyCode: string = taxonomy.Taxonomy?.Codings[0].Code;
      // retrieve any content that we have stored already and show it
      const currentContent = getContentFromStore(taxonomyCode);
      currentContent ? content.value = currentContent.ContentList : content.value = [] as ContentList[];
      // hit the back end for any content we may be missing based upon the selected filters
      const selected = await getContent(taxonomyCode, filter.selectedContentTypesIds);
      // reset the tables page number
      TableSettingsModule.updatePageNumber(1);
      // update the content that is shown to the user
      setSelectedContent(selected);
      SearchModule.updateSelectedSearchTerm(undefined);
    };

    const taxonomiesPerCategoryCounter = (categoryId) => {
      return TaxonomiesModule.getTaxonomies.filter(
        (taxonomyObj) => taxonomyObj.TaxonomyCategory === categoryId
      ).length;
    };

    const initAllContent = async () => {
      primaryTaxonomyLoading.value = true;
      // get the 'primary' taxonomy, then get the rest
      const primary = categoryTabs.value.filter(category => category.ordinal === 0);
      await getTaxonomies(primary);
      primaryTaxonomyLoading.value = false;
      taxonomiesLoading.value = true;
      const secondary = categoryTabs.value.filter(category => category.ordinal !== 0);
      getTaxonomies(secondary).then(() => {
        taxonomiesLoading.value = false;
      });
      if (firstCategory.value && isFirst.value) {
        if (!doSearch.value) {
          isFirst.value = false;
          setSelectedCategoryTab(firstCategory.value.id, firstCategory.value.label);
        } else {
          loadContentPerSearchTerm(searchTerm.value);
        }
      }
    };

    const initializeContent = async () => {
      getFavorites();
      getEdited();
      getPublishedHistory();
      initAllContent();
    };

    const closeFilter = () => {
      isFilterOpen.value = false;
    };

    const sortChanged = (sort: string) => {
      filter.sortBy = sort;
    };

    const refreshContentForFiltersChanged = async () => {
      // flag content as loading, this will be turned off later by
      // another function, but this starts the skeleton loader on filter change
      contentLoading.value = true;
      if (taxonomiesPerSelectedCategory.value &&
        taxonomiesPerSelectedCategory.value.length > 0) {
        setSelectedTaxonomy(taxonomiesPerSelectedCategory.value[selectedTaxonomy.value]);
        getContentCountsForOtherTaxonomies();
      }
    };

    const sideFiltersChanged = async (query) => {
      selectedAgeGroupIds.value = query.ageGroupIds;
      selectedGender.value = query.sex;
      selectedMediaType.value = query.mediaType;
      TableSettingsModule.updatePageNumber(1);

      // clear content due to filter changes
      ContentModule.clearContent();

      if (Object.keys(selectedCategory.value).length === 0) {
        loadContentPerSearchTerm(searchTerm.value);
      } else {
        refreshContentForFiltersChanged();
      }
    };

    const selectedContentTypesChange = (selectedContentTypes) => {
      filter.selectedContentTypes = selectedContentTypes;
      filter.selectedContentTypesIds = selectedContentTypes.map(contentTypeName => {
        return contentTypeStore.getContentTypeIdByContentTypeName(contentTypeName);
      });
      TableSettingsModule.updatePageNumber(1);
      // clear content due to filter changes
      ContentModule.clearContent();

      if (areSearchResultsVisible.value) {
        loadContentPerSearchTerm(searchTerm.value);
      } else if (selectedTaxonomy.value !== -1) {
        refreshContentForFiltersChanged();
      }
    };

    const setInitialFilterValues = (values) => {
      filter.sortBy = values.sortBy;
      filter.selectedContentTypes = values.selectedContentTypes;
      filter.selectedContentTypesIds = values.selectedContentTypes.map(contentTypeName => {
        return contentTypeStore.getContentTypeIdByContentTypeName(contentTypeName);
      });
      selectedAgeGroupIds.value = values.ageGroupIds;
      selectedGender.value = values.sex;
      selectedMediaType.value = values.mediaType;
      initializeContent();
    };

    watch(firstCategory, (newCategory) => {
      setSelectedCategoryTab(newCategory.id, newCategory.label);
    });

    watch(selectedCategory, (newCategory, oldCategory) => {
      if (newCategory.label !== oldCategory.label) {
        taxonomiesPerSelectedCategory.value.length > 0
          ? setSelectedTaxonomy(taxonomiesPerSelectedCategory.value[0])
          : setSelectedTaxonomy(null);
        selectedTaxonomy.value = 0;
        if (!doSearch.value) {
          getContentCountsForOtherTaxonomies();
        }
      }
    });

    watch(() => content.value, (newContent, oldContent) => {
      if (newContent !== oldContent && newContent !== undefined) {
        const _filteredContent = filterContent(filter.sortBy, filter.selectedContentTypes, newContent);
        filteredContent.value = _filteredContent;

        if (taxonomiesPerSelectedCategory.value.length && !areSearchResultsVisible.value) {
          taxonomyTitleCount.value = ContentModule.getTaxonomyTitleCount(getSelectedTaxonomyCode());
        } else if (!areSearchResultsVisible.value) {
          taxonomyTitleCount.value = filteredContent.value.length;
        }
        ContentModule.updateDisplayedContentCount(taxonomyTitleCount.value);
      }
    });

    const updateDisplayedContent = (filter) => {
      const _filteredContent = filterContent(filter.sortBy, filter.selectedContentTypes, content.value);
      filteredContent.value = _filteredContent;
    };

    const refreshContentForSortByFilterChanged = async (filter, newFilter) => {
      // clean content
      newContent.value = false;
      filteredContent.value = [];
      // need to make check for user settings here, and either do getContent call or backgroundload call
      if (taxonomiesPerSelectedCategory.value[selectedTaxonomy.value] && pageNumber.value !== 1) {
        // updating the page number fires off a get content request via a watcher
        TableSettingsModule.updatePageNumber(1);
        getContentCountsForOtherTaxonomies();
      } else if (taxonomiesPerSelectedCategory.value[selectedTaxonomy.value]) {
        await getContent(getSelectedTaxonomyCode(), filter.selectedContentTypesIds);
      }
      updateDisplayedContent(newFilter);
    };

    watch(() => ({ ...filter }), async (newFilter, oldFilter) => {
      // only do this stuff if the filters have actually changed
      if (JSON.stringify(newFilter.sortBy) !== JSON.stringify(oldFilter.sortBy)) {
        refreshContentForSortByFilterChanged(filter, newFilter);
      }
    });

    watch(() => ContentModule.getFavoritesLength, (length) => {
      if (length) {
        updateDisplayedContent(filter);
      }
    });

    watch(newContent, async (newVal, oldVal) => {
      if (newVal && newVal !== oldVal && selectedTaxonomy) {
        const taxonomyCode = getSelectedTaxonomyCode();
        const newContentForTaxonomy = (await getContent(taxonomyCode)).ContentList;
        if (newContentForTaxonomy) {
          content.value = newContentForTaxonomy.ContentList;
        }
        newContent.value = false;
      }
    });

    // reload content once user clicking on home page icon, value triggering from app.vue
    // app needs initAllContent again due to missing settings: Taxonomy
    watch(isReloadContent, async (newVal, oldVal) => {
      if (newVal === false) return;
      if (newVal !== oldVal) {
        SearchModule.updateResultsVisible(false);
        isFirst.value = true;
        await initAllContent();
      }
      // disable reload content
      SearchModule.setReloadContent(false);
    });

    const contentCountForTaxonomy = (taxonomyCode) => {
      return ContentModule.getTaxonomyTitleCount(taxonomyCode) ?? 0;
    };

    const changeSelectedTaxonomy = (selectedItem) => {
      selectedTaxonomy.value = selectedItem.index;
      setSelectedTaxonomy(taxonomiesPerSelectedCategory.value[selectedItem.index]);
    };

    return {
      categoryTabs,
      selectedCategory,
      setSelectedCategoryTab,
      content,
      selectedTaxonomy,
      indexedTaxonomies,
      setSelectedTaxonomy,
      taxonomiesPerSelectedCategory,
      taxonomiesPerCategoryCounter,
      searchTerm,
      loadContentPerSearchTerm,
      contentLoading,
      primaryTaxonomyLoading,
      taxonomiesLoading,
      activetab,
      formatText,
      isFilterOpen,
      filteredContent,
      closeFilter,
      sortChanged,
      sideFiltersChanged,
      selectedContentTypesChange,
      setInitialFilterValues,
      areSearchResultsVisible,
      taxonomyContentLoading,
      taxonomiesWithContent,
      filter,
      taxonomyTitleCount,
      contentContainer,
      contentCountForTaxonomy,
      otherTaxonomyCountsLoading,
      getFirstCodingsCode,
      changeSelectedTaxonomy,
      showTaxonomyCounts,
    };
  },
});
