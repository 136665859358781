import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { Details } from "../../../models/sessionDetails/sessionDetails";
export const useSessionDetailsStore = defineStore("SessionDetails", () => {
  const sessionDetails = ref({} as Details);

  const getDetails = computed(() => sessionDetails.value);

  function setDetails (details: Details) {
    sessionDetails.value = details;
  }
  return { sessionDetails, getDetails, setDetails };
});
