import { computed } from "vue";
import { useContentStore } from "@/store/modules/Content/content";

enum PrescribedTypes {
  PrintedOnly = "PrintedOnly",
  Printed = "Printed",
  Shared = "Shared",
  Recalled = "Recalled"
}

export default function PrescribedType (props) {
  const getMaterialPublishedHistory = () => {
    return computed(() => {
      const ContentModule = useContentStore();
      const publishedHistory = ContentModule.publishedHistory;
      return publishedHistory[props.material.FrontEndId] ?? {};
    });
  };
  const getPrescribedType = (materialPublishedHistory) => {
    return computed(() => {
      const autoAssigned = materialPublishedHistory.value.methodOfAssignment;
      const type = materialPublishedHistory.value.patientPrescribedType;
      switch (type) {
        case PrescribedTypes.PrintedOnly:
          return "Printed Only";
        case PrescribedTypes.Printed:
          return "Printed & Shared";
        case PrescribedTypes.Shared:
          return autoAssigned ? `Auto-${type}` : type;
        default:
          return type;
      }
    });
  };
  return { getPrescribedType, getMaterialPublishedHistory };
};
