import VueRouter, { RouteConfig } from "vue-router";
import { useTableSettingsStore } from "@/store/modules/TableSettings/tableSettings";
import { useContentStore } from "@/store/modules/Content/content";
const PatientEncounter = () => import("../views/PatientEncounter.vue");
const Favorites = () => import("../views/Favorites.vue");
const History = () => import("../views/History.vue");
const SessionDetails = () => import("../views/SessionDetails.vue");
const Diagnostics = () => import("../views/Diagnostics.vue");
const DiagnosticsSAML = () => import("../views/DiagnosticsSAML.vue");
const ErrorPage = () => import("../views/ErrorPage.vue");
const SessionExpired = () => import("../views/SessionExpired.vue");
const Folders = () => import("../views/Folders.vue");

const routes: Array<RouteConfig> = [
  {
    path: "/patientEncounter",
    name: "PatientEncounter",
    props: true,
    component: PatientEncounter,
  },
  {
    path: "/folders",
    name: "Folders",
    component: Folders,
  },
  {
    path: "/favorites",
    name: "Favorites",
    component: Favorites,
  },
  {
    path: "/history",
    name: "History",
    component: History,
  },
  {
    path: "/sessionDetails",
    name: "SessionDetails",
    component: SessionDetails,
  },
  {
    path: "/diagnostics",
    name: "Diagnostics",
    component: Diagnostics,
  },
  {
    path: "/saml-diagnostics",
    name: "Saml Diagnostics",
    component: DiagnosticsSAML,
  },
  {
    path: "/error",
    name: "error",
    props: true,
    component: ErrorPage,
  },
  {
    path: "/sessionExpired",
    name: "SessionExpired",
    component: SessionExpired,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (from.query.state && !to.query.state) {
    to.query.state = from.query.state;
  }
  const TableSettingsModule = useTableSettingsStore();
  const ContentModule = useContentStore();
  TableSettingsModule.updatePageNumber(1);
  ContentModule.setPreviewedMaterialIndex(1);
  next();
});

export default router;
