
import { defineComponent, onMounted, computed, onUnmounted, ref } from "vue";
import ContentToolbar from "../components/ContentToolbar.vue";
import ContentContainer from "../components/ContentContainer.vue";
import SkeletonLoader from "../components/SkeletonLoader.vue";

import { useContentStore } from "@/store/modules/Content/content";
import { useTableSettingsStore } from "@/store/modules/TableSettings/tableSettings";

import FavoritesService from "../services/favoritesService";
export default defineComponent({
  name: "Favorites",
  components: {
    ContentToolbar,
    ContentContainer,
    SkeletonLoader,
  },
  setup () {
    const TableSettingsModule = useTableSettingsStore();
    const ContentModule = useContentStore();
    const loading = ref(false);
    const favoritesContent = computed(() => {
      return ContentModule.getFavoritesContentArray;
    });

    const loadFavoritesContent = () => {
      loading.value = true;
      FavoritesService.getFavoritesContent().then(favoritesContent => {
        ContentModule.updateFavoritesContent(favoritesContent);
        loading.value = false;
      });
    };

    onMounted(() => {
      ContentModule.setPage(true);
      TableSettingsModule.updateFavoritesView(true);
      TableSettingsModule.updatePageNumber(1);
      if (ContentModule.needToLoadFavoriteContent) {
        loadFavoritesContent();
      }
    });

    onUnmounted(() => {
      ContentModule.setPage(false);
      TableSettingsModule.updateFavoritesView(false);
    });

    return {
      favoritesContent,
      loading,
    };
  },
});
