import { CartItem, Language, User, EducationCartModel } from "@/models/educationCart/educationCart";
import EducationCartService from "@/services/educationCartService";
import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useEducationCartStore = defineStore("EducationCart", () => {
  const educationCart = ref<EducationCartModel>({
    PatientEmailAddress: "",
    PrintResultType: -1,
    DistributionAction: -1,
    RemovePrintedItems: false,
    PublishToPortal: false,
    TimeZoneOffset: -1,
    Name: "",
    AddPatientName: false,
    AddToAvs: false,
    CartItems: [] as CartItem[],
    Languages: [] as Language[],
    LastModified: "",
    OverrideLanguage: false,
    PatientId: "",
    PrintCopies: -1,
    PrintSize: -1,
    User: {} as User,
    UserId: -1,
    VideoTranscript: false,
  });

  const getEducationCart = computed(() => educationCart.value);
  const getAVS = computed(() => educationCart.value.AddToAvs);
  const getPersonalization = computed(() => educationCart.value.AddPatientName);
  const getVideoTranscript = computed(() => educationCart.value.VideoTranscript);
  const getPrintSize = computed(() => educationCart.value.PrintSize);

  function setCart (content: EducationCartModel) {
    educationCart.value = content;
  }

  async function updateCart ({ settingName, value }) {
    educationCart.value[settingName] = value;
    await EducationCartService.updateEducationCart(educationCart.value);
  }

  return { educationCart, getEducationCart, getAVS, getPersonalization, getVideoTranscript, getPrintSize, setCart, updateCart };
});
