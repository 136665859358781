
import { defineComponent } from "vue";
import { useUserStore } from "@/store/modules/User/user";

export default defineComponent({
  setup () {
    const UserModule = useUserStore();
    const timeout = UserModule.getSessionSettings?.expirationTimeout;
    return { timeout };
  },
});
