
import { defineComponent, ref, computed, watch } from "vue";
import { useRouter } from "vue-router/composables";
import { useIdleTimeStore } from "@/store/modules/IdleTime/idleTime";
import { useAuthStore } from "@/store/modules/Auth/auth";

export default defineComponent({
  name: "SessionExpirationDialog",
  setup () {
    const AuthModule = useAuthStore();
    const IdleTimeModule = useIdleTimeStore();
    let timerId = 0;
    const router = useRouter();
    const totalSecondsLeft = ref(97);
    const expireTitle = "Session About to Expire";
    const isDialogVisible = computed(() => AuthModule.getIsDialogVisible);

    const minutesLeft = computed((): number => {
      return Math.trunc(totalSecondsLeft.value / 60);
    });

    const secondsLeft = computed((): number => {
      return totalSecondsLeft.value % 60;
    });

    const startCountDownTimer = async () => {
      if (totalSecondsLeft.value > 0) {
        timerId = setTimeout(() => {
          totalSecondsLeft.value--;
          startCountDownTimer();
        }, 1000);
      } else { // ran out of time, log them out
        clearTimeout(timerId);
        timerId = 0;
        AuthModule.setIsDialogVisible(false);
        await AuthModule.expireToken();
        if (router) {
          console.log("session expired from session expire");
          router.push({ path: "sessionExpired?state=timedOut" });
        }
      }
    };

    const continueSession = (): void => {
      clearTimeout(timerId);
      timerId = 0;
      IdleTimeModule.RestartIdleWatch();
    };

    watch(() => IdleTimeModule.showDialog, (showDialog) => {
      AuthModule.setIsDialogVisible(showDialog);
      if (showDialog) {
        totalSecondsLeft.value = IdleTimeModule.getWarningTimeSeconds;
        startCountDownTimer();
      }
    });

    return {
      isDialogVisible,
      expireTitle,
      continueSession,
      minutesLeft,
      secondsLeft,
      startCountDownTimer,
    };
  },
});
