
import { defineComponent, watch, computed, WritableComputedRef } from "vue";
import { useTableSettingsStore } from "@/store/modules/TableSettings/tableSettings";
import { useContentStore } from "@/store/modules/Content/content";
import { useUserStore } from "@/store/modules/User/user";
import { useHistoryStore } from "@/store/modules/History/history";

export default defineComponent({
  name: "TableControls",
  setup () {
    const ContentModule = useContentStore();
    const HistoryModule = useHistoryStore();
    const UserModule = useUserStore();
    const TableSettingsModule = useTableSettingsStore();
    const isFolderView = computed(() => TableSettingsModule.viewingFolders);
    const isHistoryView = computed(() => TableSettingsModule.viewingHistory);
    const isFavoritesView = computed(() => TableSettingsModule.viewingFavorites);
    const isGridView = computed(() => UserModule.isGridView);
    const rowsPerPageItems = computed(() => TableSettingsModule.getRowsPerPageType);

    const rowsPerPage: WritableComputedRef<number> = computed({
      get (): number {
        return TableSettingsModule.getRowsPerPage;
      },
      set (newVal: number): void {
        TableSettingsModule.updateSelectedRowsPerPage(newVal);
      },
    });

    const pageNumber: WritableComputedRef<number> = computed({
      get (): number {
        return TableSettingsModule.pageNumber;
      },
      set (newValue: number): void {
        TableSettingsModule.updatePageNumber(newValue);
      },
    });
    const updateTableView = (view) => {
      TableSettingsModule.setTableView(view);
    };

    const changeResultsPerPage = (newValue) => {
      if (UserModule.isGridView) {
        UserModule.updateCardsPerPage(newValue);
      } else {
        UserModule.updateRowsPerPage(newValue);
      }
    };

    watch(isGridView, (newVal) => {
      if (newVal) {
        updateTableView("grid");
      } else {
        updateTableView("list");
      }
    });

    watch(isHistoryView, (newVal) => {
      if (newVal) {
        updateTableView("list");
      } else if (isGridView.value) {
        updateTableView("grid");
      }
    });

    const totalItems = computed(() => {
      let itemCount = 0;
      if (isHistoryView.value) {
        itemCount = HistoryModule.displayedContentCount;
      } else if (isFavoritesView.value) {
        itemCount = ContentModule.favoritesDisplayedContentCount;
      } else {
        itemCount = ContentModule.displayedContentCount;
      }
      return itemCount;
    });

    const getPaginationString = () => {
      const start = (rowsPerPage.value * (pageNumber.value - 1)) + 1;
      return `${start}-${Math.min(start + rowsPerPage.value - 1, totalItems.value)} of ${totalItems.value}`;
    };

    return {
      changeResultsPerPage,
      rowsPerPageItems,
      rowsPerPage,
      pageNumber,
      totalItems,
      isGridView,
      isFolderView,
      getPaginationString,
    };
  },
});
