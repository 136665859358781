
import { defineComponent } from "vue";

export default defineComponent({
  name: "PrivacyPolicyDialog",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    content: {
      required: true,
    },
  },
});
