<template>
  <div class="text-center col-12 col-md-12 col-lg-12 col">
    <div >
    <v-row class="col-12 col-md-12 col-lg-12 justify-left cards-container">
      <div  v-for="i in Math.floor(pageSize)" :key="i">
      <v-col>
        <GridViewCard
          v-if="tablatedContent[(i)-1]"
          :material="tablatedContent[(i)-1]"
          :index="(i)-1"
          @onOpenPreview='$emit("onOpenPreview", $event)'
          @onCartLimitExceeded="$emit('onCartLimitExceeded')"
          @hook:mounted="onCardMounted(content.length, pageSize, (i)-1)"
        />
      </v-col>
      </div>
    </v-row>
  </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import GridViewCard from "./GridViewCard.vue";
import { useTableSettingsStore } from "@/store/modules/TableSettings/tableSettings";

export default defineComponent({
  components: {
    GridViewCard,
  },
  props: {
    content: {
      type: Array,
      default: () => ([]),
    },
    onCardMounted: {
      type: Function,
    },
  },
  setup (props) {
    const TableSettingsModule = useTableSettingsStore();
    const pageNumber = computed(() => TableSettingsModule.pageNumber);
    const pageSize = computed(() => TableSettingsModule.rowsPerPage);
    const viewingFavorites = computed(() => TableSettingsModule.viewingFavorites);
    const tablatedContent = computed(() => {
      if (viewingFavorites.value) {
        return props.content.slice(
          (pageNumber.value - 1) * pageSize.value,
          pageNumber.value * pageSize.value);
      } else {
        return props.content;
      }
    });
    return {
      pageNumber, pageSize, tablatedContent,
    };
  },
});
</script>

<style lang="scss" scoped>
  .table-wrapper {
    display: flex;
    justify-content: space-around;
  }
.cards-container{
padding-left: 5%;
}
</style>
