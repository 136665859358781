
import { defineComponent, ref, onMounted, watch, computed, onUnmounted } from "vue";
import ContentToolbar from "../components/ContentToolbar.vue";
import ContentContainer from "../components/ContentContainer.vue";
import SkeletonLoader from "../components/SkeletonLoader.vue";
import FoldersService from "../services/foldersService";
import PublishedHistoryService from "@/services/publishedHistoryService";
import { useFoldersStore } from "@/store/modules/Folders/folders";
import { useTableSettingsStore } from "@/store/modules/TableSettings/tableSettings";
import { useContentStore } from "@/store/modules/Content/content";
import { FoldersModel } from "../models/folders/folders";

export default defineComponent({
  name: "Folders",
  components: {
    ContentToolbar,
    ContentContainer,
    SkeletonLoader,
  },
  setup () {
    const Folders = useFoldersStore();
    const TableSettingsModule = useTableSettingsStore();
    const ContentModule = useContentStore();
    const loading = ref(true);
    const pageTitle = ref("Folders");
    const breadcrumbItems = ref([
      {
        text: "Folders",
        to: "0",
      },
    ]);

    const getFolders = async () => {
      if (!Folders.folders.length) {
        await FoldersService.getFolders().then(res => {
          Folders.setFolders(res);
        });
      }
    };

    const foldersContent = ref([] as FoldersModel[]);
    const materialsContent = computed(() => {
      return Folders.getFolderMaterials;
    });

    const getFolderMaterials = async (folderId) => {
      loading.value = true;
      if (!Folders.folderMaterials.length) {
        await FoldersService.getFolderMaterials(folderId).then(res => {
          const folderMaterials = res.length > 0 ? res.sort((a, b) => a.Title.localeCompare(b.Title)) : res;
          Folders.updateMaterials(folderMaterials);
        });
      }
      loading.value = false;
    };

    const selectedFolder = ref(0);

    const selectFolder = (folderId) => {
      getFolderMaterials(folderId);
      selectedFolder.value = folderId;
    };

    watch(() => selectedFolder.value, (n) => {
      const folder = Folders.folders.find(x => x.Id === n);
      !folder
        ? pageTitle.value = "Folders"
        : pageTitle.value = folder.DisplayName;

      const index = breadcrumbItems.value.findIndex(x => x.to === n.toString());
      index >= 0
        ? breadcrumbItems.value.splice(index + 1)
        : breadcrumbItems.value.push({
          text: folder ? folder.DisplayName : "Folders",
          to: folder ? folder.Id.toString() : "0",
        });

      n !== 0
        ? foldersContent.value = Folders.folders.filter(x => x.ParentFolderId === n)
        : foldersContent.value = Folders.getFirstLevelFolders;
    });

    const initializeContent = async () => {
      if (JSON.stringify(ContentModule.publishedHistory) === "{}") {
        PublishedHistoryService.getPublishedHistory().then(res => {
          ContentModule.setPublishedMaterials(res);
        });
      }
      await getFolders();
      selectFolder(0);
      foldersContent.value = Folders.getFirstLevelFolders;
      loading.value = false;
    };

    onMounted(() => {
      initializeContent();
      TableSettingsModule.updateFoldersView(true);
    });

    onUnmounted(() => {
      TableSettingsModule.updateFoldersView(false);
    });

    return {
      loading,
      foldersContent,
      materialsContent,
      selectFolder,
      breadcrumbItems,
      selectedFolder,
      pageTitle,
    };
  },
});
