import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { FoldersModel } from "@/models/folders/folders";
import { ContentList } from "@/models/content/content";
import createFrontEndId from "@/utility/createFrontEndId";
import { useContentStore } from "../Content/content";
export const useFoldersStore = defineStore("Folders", () => {
  // state
  const folders = ref<FoldersModel[]>([]);
  const folderMaterials = ref<{ [key: string]: ContentList}>({});

  // getters
  const getFirstLevelFolders = computed(() => folders.value.filter(x => !x.ParentFolderId));
  const getFolderMaterials = computed(() => folderMaterials.value);
  const getCountOfMaterials = computed(() => Object.keys(folderMaterials.value).length);

  // actions
  function setFolders (data: FoldersModel[]) {
    folders.value = data.sort((a, b) => a.DisplayName.localeCompare(b.DisplayName));
  }

  function updateMaterials (data) {
    const materials: { [key: string]: ContentList } = data.reduce((previousValue, currentValue) => {
      currentValue.FrontEndId = createFrontEndId(currentValue);
      if (!previousValue[currentValue.FrontEndId]) previousValue[currentValue.FrontEndId] = currentValue;
      return previousValue;
    }, {});
    // now for each material, check if they are in the edited array in the content module
    const ContentModule = useContentStore();
    const edited = ContentModule.getEdited;
    Object.values(materials).forEach(contentItem => {
      for (let i = 0; i < edited.length; i++) {
        if (edited[i].ContentId === contentItem.ContentId) {
          contentItem.IsInlineEdited = true;
          break;
        }
      }
    });
    folderMaterials.value = materials;
  }

  function toggleEdited (data) {
    const { contentId, isEdited } = data;
    const contentList: ContentList[] = Object.values(folderMaterials.value);
    const content: ContentList | undefined = contentList.find((t: ContentList) => t.ContentId === contentId);
    if (content) {
      content.IsInlineEdited = isEdited;
    }
  }

  return { folders, folderMaterials, getFirstLevelFolders, getFolderMaterials, getCountOfMaterials, setFolders, updateMaterials, toggleEdited };
});
