
import { defineComponent, computed } from "vue";
import { useUserStore } from "@/store/modules/User/user";
import { useUIConfigurationStore } from "@/store/modules/UIConfiguration/uiConfiguration";
import { useTableSettingsStore } from "@/store/modules/TableSettings/tableSettings";
import PrinterIcon from "../components/icons/PrinterIcon.vue";

export default defineComponent({
  name: "PrintMenu",
  props: ["on", "attrs", "title", "disabled"],
  components: { PrinterIcon },
  setup () {
    const TableSettingsModule = useTableSettingsStore();
    const UserModule = useUserStore();
    const UiConfigurationModule = useUIConfigurationStore();
    const userDisplaySettings = computed(() => UserModule.getDisplaySettings);
    const uiConfiguration = computed(() => UiConfigurationModule.uiConfiguration);
    const viewingFolders = computed(() => TableSettingsModule.viewingFolders);
    const gridView = computed(() => TableSettingsModule.view === "grid");
    return { userDisplaySettings, uiConfiguration, viewingFolders, gridView };
  },
});
