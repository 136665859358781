import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { PatientModel } from "../../../models/patient/patient";
import { useUserStore } from "../User/user";
import { usePrintShareStore } from "../PrintShare/printShare";
export const usePatientStore = defineStore("Patient", () => {
  const UserModule = useUserStore();
  const PrintShare = usePrintShareStore();
  const patient = ref<PatientModel>({
    Name: "",
    DisplayName: "",
    GivenName: "",
    FamilyName: "",
    AgeGroupId: -1,
    Gender: {},
    Age: "",
    BirthDate: "",
    EmailAddress: "",
    PreferredLanguageCode: "",
    Address: {},
  } as PatientModel);
  const isPatientEncounterVisible = ref(false);

  // getters
  const getPatient = computed(() => patient.value);
  const getAgeGroupId = computed(() => patient.value.AgeGroupId);
  const getGender = computed(() => patient.value.Gender);
  const getEmail = computed(() => patient.value.EmailAddress);
  const getAddress = computed(() => patient.value.Address);

  // actions
  function setPatient (newPatient: PatientModel) {
    patient.value = newPatient;
    if (UserModule.isPatientPortalEnabled && getEmail.value) {
      PrintShare.updateTriggerEmailNotificationDialog(false);
    }
  }

  function setEmail (email: string) {
    patient.value.EmailAddress = email;
    const PrintShare = usePrintShareStore();
    PrintShare.updateTriggerEmailNotificationDialog(false);
  }

  function setIsPatientEncounterVisible (visible: boolean) {
    isPatientEncounterVisible.value = visible;
  }

  return { patient, isPatientEncounterVisible, getPatient, getAgeGroupId, getGender, getEmail, getAddress, setPatient, setEmail, setIsPatientEncounterVisible };
});
