import { TaxonomyCode } from "../taxonomy/taxonomy";

export interface Content {
  ContentList: ContentList;
  CustomJson: string;
  SearchTerms: any;
  Taxonomies: TaxonomyCode [];
  TotalResults: number;
}

export function createEmptyContent () {
  return {
    ContentList: {} as ContentList,
    CustomJson: "",
    SearchTerms: {},
    Taxonomies: [] as TaxonomyCode[],
    TotalResults: 0,
  };
}

export interface TaxonomyCodeToTitles {
  TaxonomyCode: string;
  Titles: number;
}

export interface ContentList {
  [x: string]: any;
  ContentId: string;
  ContentOrigin: number;
  ContentTypeId: number;
  EnglishOtherLanguageRequest: EnglishOtherLanguageRequest;
  Id: number;
  IsCustom: boolean;
  IsFavorite: boolean;
  IsInlineEdited: boolean;
  IsTranscript: boolean;
  IsVideo: boolean;
  Language: Language;
  LanguageCode: string;
  Link: Link;
  OtherLanguages: OtherLanguages [];
  ParentFolderId: any;
  ParentFolderName: any;
  PublishedDate: string;
  SearchRank: number;
  SortOrder: number;
  Taxonomy: TaxonomyCode;
  ThumbnailImageUri: string;
  Title: string;
  key: any;
}

export interface PublishedMaterial {
  [x: string]: any;
  contentId: string;
  contentTypeId: number;
  isEdited: boolean;
  language: string;
  patientPrescribedDate: string;
  patientPrescribedType: string;
  percentViewed: number;
  read: string;
  regularTitle: string;
  title: string;
  methodOfAssignment: number;
}

export interface Favorite {
  [x: string]: any;
  ContentId: string;
  ContentOrigin: number;
  ContentTypeId: number;
  ContentTypeName: string;
  EnglishOtherLanguageRequest: EnglishOtherLanguageRequest;
  Id: number;
  IsCustom: boolean;
  IsFavorite: boolean;
  IsInlineEdited: boolean;
  IsTranscript: boolean;
  IsVideo: boolean;
  Language: Language;
  LanguageCode: string;
  Link: Link;
  OtherLanguages: OtherLanguages [];
  ParentFolderId: any;
  ParentFolderName: any;
  PublishedDate: string;
  SearchRank: number;
  SortOrder: number;
  Taxonomy: any;
  ThumbnailImageUri: string;
  Title: string;
}

export interface Edited {
  [x: string]: any;
  ContentId: string;
  ContentOrigin: number;
  ContentTypeId: number;
  ContentTypeName: string;
  EnglishOtherLanguageRequest: EnglishOtherLanguageRequest;
  Id: number;
  IsCustom: boolean;
  IsFavorite: boolean;
  IsInlineEdited: boolean;
  IsTranscript: boolean;
  IsVideo: boolean;
  Language: Language;
  LanguageCode: string;
  Link: Link;
  OtherLanguages: OtherLanguages [];
  ParentFolderId: any;
  ParentFolderName: any;
  PublishedDate: string;
  SearchRank: number;
  SortOrder: number;
  Taxonomy: any;
  ThumbnailImageUri: string;
  Title: string;
}

export function createEditedItem ({ contentId, contentTypeId }): Edited {
  return {
    ContentId: contentId,
    ContentOrigin: 0,
    ContentTypeId: contentTypeId,
    ContentTypeName: "",
    EnglishOtherLanguageRequest: {
      ContentId: "",
      ContentTypeId: 0,
    },
    Id: 0,
    IsCustom: false,
    IsFavorite: false,
    IsInlineEdited: false,
    IsTranscript: false,
    IsVideo: false,
    Language: {
      Code: "",
      Direction: "",
      DisplayCode: "",
      DisplayName: "",
      GsaName: "",
      Position: 0,
      UCRCode: "",
    },
    LanguageCode: "",
    Link: {
      Href: "",
      Hreflang: "",
      Rel: "",
      Title: "",
      Type: "",
    },
    OtherLanguages: [],
    ParentFolderId: null,
    ParentFolderName: null,
    PublishedDate: "",
    SearchRank: 0,
    SortOrder: 0,
    Taxonomy: null,
    ThumbnailImageUri: "",
    Title: "",
  };
}
export interface EnglishOtherLanguageRequest {
  ContentId: string;
  ContentTypeId: number;
}

export interface Language {
  Code: string;
  Direction: string;
  DisplayCode: string;
  DisplayName: string;
  GsaName: string;
  Position: number;
  UCRCode: string;
}

export interface Link {
  Href: string;
  Hreflang: string;
  Rel: string;
  Title: string;
  Type: string;
}

export interface OtherLanguages {
  Code: string;
  ContentId: string;
  ContentTypeId: number;
  DisplayCode: string;
  DisplayName: string;
}
