
import { defineComponent, computed, Component } from "vue";
import InitialLoader from "./skeletonLoaderTypes/InitialLoader.vue";
import { useUserStore } from "@/store/modules/User/user";
const ListItemLoader = () => import("./skeletonLoaderTypes/ListItemLoader.vue");
const GridItemLoader = () => import("./skeletonLoaderTypes/GridItemLoader.vue");
const TaxonomiesTabsLoader = () => import("./skeletonLoaderTypes/TaxonomiesTabsLoader.vue");
const TitleBarLoader = () => import("./skeletonLoaderTypes/TitleBarLoader.vue");
const HistoryRowLoader = () => import("./skeletonLoaderTypes/HistoryRowLoader.vue");
const HeaderLoader = () => import("./skeletonLoaderTypes/HeaderLoader.vue");
const SearchLoader = () => import("./skeletonLoaderTypes/SearchLoader.vue");
const CategoriesLoader = () => import("./skeletonLoaderTypes/CategoriesLoader.vue");
const ToggleRowLoader = () => import("./skeletonLoaderTypes/ToggleRowLoader.vue");

enum LoaderTypes {
  Initial = "initial",
  ListItem = "listItem",
  GridListItem = "gridListItem",
  TaxonomiesTabs = "taxonomiesTabs",
  HistoryRow = "historyRow",
  Header = "header",
  Search = "search",
  Categories = "categories",
  ToggleRow = "toggle",
};

export default defineComponent({
  name: "SkeletonLoader",
  components: {
    InitialLoader,
    ListItemLoader,
    GridItemLoader,
    TaxonomiesTabsLoader,
    TitleBarLoader,
    HistoryRowLoader,
    HeaderLoader,
    SearchLoader,
    CategoriesLoader,
    ToggleRowLoader,
  },
  props: {
    type: {
      type: String,
      default: "initial",
    },
    foldersView: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const UserModule = useUserStore();
    const loader = computed(() => {
      let loaderType: Component | null = null;
      switch (props.type) {
        case LoaderTypes.Initial:
          loaderType = InitialLoader;
          break;
        case LoaderTypes.ListItem:
          loaderType = defineComponent(ListItemLoader);
          break;
        case LoaderTypes.GridListItem:
          loaderType = defineComponent(GridItemLoader);
          break;
        case LoaderTypes.TaxonomiesTabs:
          loaderType = defineComponent(TaxonomiesTabsLoader);
          break;
        case LoaderTypes.HistoryRow:
          loaderType = defineComponent(HistoryRowLoader);
          break;
        case LoaderTypes.Header:
          loaderType = defineComponent(HeaderLoader);
          break;
        case LoaderTypes.Search:
          loaderType = defineComponent(SearchLoader);
          break;
        case LoaderTypes.Categories:
          loaderType = defineComponent(CategoriesLoader);
          break;
        case LoaderTypes.ToggleRow:
          loaderType = defineComponent(ToggleRowLoader);
          break;
      }
      return loaderType;
    });

    const displaySettings = computed(() => {
      return UserModule.getDisplaySettings.activityHistory;
    });

    return { displaySettings, loader };
  },
});
