import { ref } from "vue";
import { usePrintShareStore } from "@/store/modules/PrintShare/printShare";
import { useContentStore } from "@/store/modules/Content/content";
import { ContentList } from "@/models/content/content";

export default function printShareFunctionality () {
  const isPrinting = ref(false);
  const isSharing = ref(false);

  async function printOrShareMaterials ({ isPrint = false, isPrintOnly = false }, materials: ContentList | null = null) {
    const ContentModule = useContentStore();
    const PrintShareModule = usePrintShareStore();
    if (isPrintOnly || isPrint) {
      isPrinting.value = true;
    } else {
      isSharing.value = true;
    }

    if (materials) {
      await PrintShareModule.printOrShareMaterial({ materials, isPrint, multiPrint: false, isPrintOnly });
    } else {
      await PrintShareModule.printOrShareMaterial({ materials: ContentModule.getselectedMaterialsArray, isPrint, multiPrint: true, isPrintOnly });
    }

    if (isPrintOnly || isPrint) {
      isPrinting.value = false;
    } else {
      isSharing.value = false;
    }
  }

  return { isPrinting, isSharing, printOrShareMaterials };
};
