import { useUIConfigurationStore } from "@/store/modules/UIConfiguration/uiConfiguration";
import { useContentStore } from "@/store/modules/Content/content";
import { ContentList } from "@/models/content/content";

const compare = function <Type> (a: Type, b: Type): number {
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};

const modifyStringForSort = (s: string) => s.replace(/\W/g, "").toUpperCase();

// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
// for details about implementing a custom compare function for array sorting
const prioritizeCustomContent = function (contentA, contentB, sortBy: string) {
  if (contentA.IsCustom && !contentB.IsCustom) {
    // If A is custom and B is not, put A first
    return -1;
  } else if (contentB.IsCustom && !contentA.IsCustom) {
    // If B is custom and A is not, put B first
    return 1;
  } else if (contentA.IsCustom && contentB.IsCustom) {
    // If both A and B are custom, use additional logic to break the tie.
    // See this Confluence article that describes how it's supposed to work:
    // https://confluence.internetbrands.com/pages/viewpage.action?pageId=176869715
    switch (sortBy) {
      case "most-relevant":
        return contentA.SearchRank - contentB.SearchRank;
      case "name":
        return compare<string>(modifyStringForSort(contentA.Title), modifyStringForSort(contentB.Title));
    }
  }

  // Keep original order of A and B
  return 0;
};

interface FavoritedOrder {
  index: number;
  content: ContentList;
}
function prioritizeByFavoritePreserveOrder (content) {
  const UIConfiguration = useUIConfigurationStore();
  const ContentModule = useContentStore();
  if (UIConfiguration.getUIConfiguration.PrioritizeByFavorite) {
    // get them in order, remove from list, push to top
    const favorited: FavoritedOrder[] = [];
    const keys = Object.keys(ContentModule.getFavorites);
    keys.forEach(frontEndId => {
      const index = content.findIndex((content) => {
        return content.FrontEndId === frontEndId;
      });
      if (index !== -1) {
        const contentItem = content.splice(index, 1)[0];
        favorited.push({ index, content: contentItem });
      }
    });

    favorited.sort((a, b) => {
      return b.index - a.index;
    }).forEach(item => {
      content.unshift(item.content);
    });
  }

  return content;
}

function filterContent (sortBy: string, selectedContentTypes: string[], content): ContentList {
  const ContentModule = useContentStore();
  let filteredArr = [...content];
  filteredArr = filteredArr.filter(c => {
    return selectedContentTypes.includes(c.ContentTypeName);
  });
  let favorites;
  switch (sortBy) {
    case "most-relevant":
      filteredArr.sort((a, b) => {
        const resultforCustom = prioritizeCustomContent(a, b, sortBy);
        return resultforCustom === 0 ? a.SearchRank - b.SearchRank : resultforCustom;
      });
      filteredArr = prioritizeByFavoritePreserveOrder(filteredArr);
      break;
    case "newest-relevant":
      filteredArr.sort((a, b) => {
        const firstDate = new Date(a.PublishedDate);
        const secondDate = new Date(b.PublishedDate);
        const sortByDate = compare<Date>(secondDate, firstDate);
        if (sortByDate === 0) {
          const firstTitle = modifyStringForSort(a.Title);
          const secondTitle = modifyStringForSort(b.Title);
          return compare<string>(firstTitle, secondTitle);
        }
        return sortByDate;
      });
      filteredArr = prioritizeByFavoritePreserveOrder(filteredArr);
      break;
    case "favorites":
      favorites = ContentModule.getFavorites;
      if (favorites) {
        const favoritesList = Object.keys(favorites).map(c => favorites[c]);
        filteredArr.sort((a, b) => {
          const firstTitle = modifyStringForSort(a.Title);
          const secondTitle = modifyStringForSort(b.Title);
          return compare<boolean>(favoritesList.some(f => f.ContentId === b.ContentId), favoritesList.some(f => f.ContentId === a.ContentId)) ||
                  compare<string>(firstTitle, secondTitle);
        });
      }
      break;
    case "name":
      filteredArr.sort((a, b) => {
        const firstTitle = modifyStringForSort(a.Title);
        const secondTitle = modifyStringForSort(b.Title);
        const resultforCustom = prioritizeCustomContent(a, b, sortBy);
        return resultforCustom === 0 ? compare<string>(firstTitle, secondTitle) : resultforCustom;
      });
      filteredArr = prioritizeByFavoritePreserveOrder(filteredArr);
      break;
    case "content-type":
      filteredArr.sort((a, b) => {
        const firstContentTypeName = modifyStringForSort(a.ContentTypeName);
        const secondContentTypeName = modifyStringForSort(b.ContentTypeName);
        const firstTitle = modifyStringForSort(a.Title);
        const secondTitle = modifyStringForSort(b.Title);
        return compare<string>(firstContentTypeName, secondContentTypeName) || compare<string>(firstTitle, secondTitle);
      });
      filteredArr = prioritizeByFavoritePreserveOrder(filteredArr);
      break;
  }

  return filteredArr as unknown as ContentList;
}

export default filterContent;
