
import { defineComponent, computed } from "vue";
import { usePatientStore } from "@/store/modules/Patient/patient";
import { useUserStore } from "@/store/modules/User/user";
export default defineComponent({
  name: "NavDrawer",
  props: {
    showRecommendedContent: {
      type: Boolean,
      default: true,
    },
    showRefresh: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  setup (_props, { emit }) {
    const UserModule = useUserStore();
    const PatientModule = usePatientStore();
    const patient = computed(() => {
      return PatientModule.getPatient;
    });
    const companyLabel = computed(() => {
      return UserModule.getCompanyLabel;
    });
    const productLabel = computed(() => {
      return UserModule.getProductLabel;
    });

    const isPatientEncounterVisible = computed(() => PatientModule.isPatientEncounterVisible);

    const setIsPatientEncounterVisible = (status: boolean) => {
      PatientModule.setIsPatientEncounterVisible(status);
    };

    const loadHelpDialog = () => {
      emit("openHelpDialog");
    };

    const isPatientPortalEnabled = computed(() => {
      return UserModule.getPatientPortalSettings;
    });
    const patientEmail = computed(() => {
      return PatientModule.getEmail;
    });
    const loadEmailDialog = () => {
      emit("openEmailDialog");
    };
    const backToHomePage = () => {
      setIsPatientEncounterVisible(true);
      emit("backToHomePage");
    };

    return {
      patient,
      companyLabel,
      productLabel,
      loadHelpDialog,
      isPatientPortalEnabled,
      patientEmail,
      loadEmailDialog,
      backToHomePage,
      setIsPatientEncounterVisible,
      isPatientEncounterVisible,
    };
  },
});
