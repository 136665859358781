import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useTableSettingsStore } from "@/store/modules/TableSettings/tableSettings";
import {
  User,
  CategoryTab,
  ActivityHistory,
  CategoryTabs,
  ContentTypeFilter,
  AvsCheckbox,
  Modals,
  PublishButton,
  PrintButton,
  Branding,
  Labels
} from "@/models/user/user";
import UserService from "@/services/userService";
export const useUserStore = defineStore("UserSettings", () => {
  const user = ref<User>({
    userSettings: {},
    displaySettings: {},
    sessionSettings: {},
    patientPortalEnabled: false,
    documentEditingEnabled: false,
  } as User); // Ensure deep reactivity and proper initialization
  const isPatientPortalEnabled = ref(false);
  const isDocumentEditingEnabled = ref(false);

  const getDisplaySettings = computed(() => {
    if (user && Object.keys(user.value?.displaySettings).length !== 0) {
      return user.value.displaySettings;
    } else {
      return {
        darkModeEnabled: false,
        filtersOpen: false,
        filtersSort: 0,
        branding: {} as Branding,
        categoryTabs: {
          tabs: [{}],
        } as CategoryTabs,
        hiddenMenuIconNames: [],
        labels: {} as Labels,
        landingRedirect: null,
        printButton: {} as PrintButton,
        publishButton: {} as PublishButton,
        cartItemLimit: {},
        modals: {} as Modals,
        help: {},
        activityHistory: {} as ActivityHistory,
        contentTypeFilter: {} as ContentTypeFilter,
        avsCheckbox: {} as AvsCheckbox,
      };
    }
  });

  const getUserSettings = computed(() => user.value?.userSettings);
  const getSessionSettings = computed(() => user.value?.sessionSettings);
  const isGridView = computed(() => {
    const isGrid = user.value?.userSettings?.SearchLayoutType === "Grid";
    const tableSettings = useTableSettingsStore();
    tableSettings.setTableView(isGrid ? "grid" : "list");
    return isGrid;
  });

  const getCompanyLabel = computed(() => user.value?.displaySettings?.branding?.companyLabel);

  const getProductLabel = computed(() => user.value?.displaySettings?.branding?.productLabel);
  const getCategoryTabs = computed(() => {
    return user.value?.displaySettings?.categoryTabs?.tabs.filter(
      tab => tab.visibility === 1
    );
  });
  const getFirstCategory = computed(() => {
    const tab = getCategoryTabs.value[0] as CategoryTab;
    if (!tab) {
      return {
        id: 0,
        label: "",
      } as CategoryTab;
    } else {
      return tab;
    }
  });

  const getPatientPortalSettings = computed(() => isPatientPortalEnabled.value);

  const getDocumentEditingEnabled = computed(() => isDocumentEditingEnabled.value);

  async function updateFiltersSort (filtersSort: number) {
    if (user.value?.userSettings) {
      user.value.userSettings.FiltersSort = filtersSort;
    }
    await UserService.updateUserSettings(user.value.userSettings);
  }

  async function updateFiltersOpen (filtersOpen: boolean) {
    if (user.value?.userSettings) {
      user.value.userSettings.FiltersOpen = filtersOpen;
    }
    await UserService.updateUserSettings(user.value?.userSettings);
  }

  async function updateDarkModeEnabled (darkModeEnabled: boolean) {
    if (user.value?.userSettings) {
      user.value.userSettings.DarkModeEnabled = darkModeEnabled;
    }
    await UserService.updateUserSettings(user.value?.userSettings);
  }

  async function updateCardsPerPage (cardsPerPage: number) {
    if (user.value?.userSettings) {
      user.value.userSettings.CardsPerPage = cardsPerPage;
    }
    await UserService.updateUserSettings(user.value?.userSettings);
  }

  async function updateRowsPerPage (rowsPerPage: number) {
    if (user.value?.userSettings) {
      user.value.userSettings.RowsPerPage = rowsPerPage;
    }
    await UserService.updateUserSettings(user.value?.userSettings);
  }

  async function updateSearchLayoutType (isGridView: boolean) {
    if (user.value?.userSettings.SearchLayoutType) {
      user.value.userSettings.SearchLayoutType = isGridView ? "Grid" : "List";
    }
    await UserService.updateUserSettings(user.value?.userSettings);
  }

  async function setDocumentEditingEnabled (statement: boolean) {
    isDocumentEditingEnabled.value = statement;
  }

  async function setPatientPortalSettings (statement: boolean) {
    isPatientPortalEnabled.value = statement;
  }

  async function setUserSettingsCartItemLimit (cartLimit: number) {
    user.value.displaySettings.cartItemLimit = cartLimit;
  }

  function setUserSettings (settings: User) {
    user.value = settings;
  }

  return { user, isPatientPortalEnabled, isDocumentEditingEnabled, isGridView, getCompanyLabel, getSessionSettings, getProductLabel, getCategoryTabs, getFirstCategory, getPatientPortalSettings, getDocumentEditingEnabled, getDisplaySettings, getUserSettings, updateFiltersSort, updateFiltersOpen, updateDarkModeEnabled, updateCardsPerPage, updateRowsPerPage, updateSearchLayoutType, setDocumentEditingEnabled, setPatientPortalSettings, setUserSettingsCartItemLimit, setUserSettings };
});
