import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const usePreviewStore = defineStore("Preview", () => {
  const videoContent = ref<string>("");
  const editableContent = ref<Element>({} as Element);
  const metadataContent = ref<Element>({} as Element);
  const isPreviewOpen = ref(false);

  const getContent = computed(() => {
    const html = document.createElement("html");

    html.innerHTML = metadataContent.value.innerHTML;

    const hSBody = html.querySelector("#HSBody") ?? document.createElement("div");
    hSBody.innerHTML = editableContent.value.innerHTML;

    return html.outerHTML;
  });

  const getEditableContent = computed(() => editableContent.value.innerHTML);

  function setPreviewContent (content: string) {
    videoContent.value = content;
    const html = document.createElement("html");
    html.innerHTML = content;
    const hSBody = html.querySelector("#HSBody") ?? document.createElement("div");
    const newEditableContent = document.createElement("div");

    newEditableContent.innerHTML = hSBody.innerHTML;

    hSBody.innerHTML = "";

    metadataContent.value = html;
    editableContent.value = newEditableContent;
  }

  function setEditableContent (content: string) {
    editableContent.value.innerHTML = content;
  }

  function setIsPreviewOpen (open: boolean) {
    isPreviewOpen.value = open;
  }

  return { videoContent, editableContent, metadataContent, isPreviewOpen, getContent, getEditableContent, setPreviewContent, setEditableContent, setIsPreviewOpen };
});
