import { HelpModel } from "@/models/help/help";
import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useHelpStore = defineStore("HelpModule", () => {
  const helpContent = ref({} as HelpModel);

  const getContent = computed(() => helpContent.value.Content);

  function setHelpContent (content: HelpModel): void {
    helpContent.value = content;
  }

  return { helpContent, getContent, setHelpContent };
});
