import { computed, reactive, ref } from "vue";
import { useContentStore } from "@/store/modules/Content/content";
import { useTableSettingsStore } from "@/store/modules/TableSettings/tableSettings";
import { usePreviewStore } from "@/store/modules/Preview/preview";
import { useHistoryStore } from "@/store/modules/History/history";
import { useFoldersStore } from "@/store/modules/Folders/folders";
import PreviewService from "@/services/previewService";
import HistoryService from "@/services/historyService";
import isEdited from "@/utility/isEdited";

export default function PreviewModalFunctionality (props) {
  const FolderModule = useFoldersStore();
  const PreviewModule = usePreviewStore();
  const HistoryModule = useHistoryStore();
  const TableSettings = useTableSettingsStore();
  const ContentModule = useContentStore();
  const previewMaterial = reactive({ material: {}, index: -1 });
  const isPreviewLoading = ref(false);
  const isPreviewVisible = ref(false);
  const reOpenPreview = ref(false);
  const nextPage = ref(false);
  const pageNumber = computed(() => TableSettings.pageNumber);
  const itemsPerPage = computed(() => TableSettings.rowsPerPage);
  const isFavoritesView = computed(() => TableSettings.viewingFavorites);
  const isFoldersView = computed(() => TableSettings.viewingFolders);
  const isHistoryView = computed(() => TableSettings.viewingHistory);
  const isOpenInPreview = computed(() => {
    const selectedIndex = ContentModule.getPreviewedMaterialIndex;
    return selectedIndex === ((pageNumber.value - 1) * itemsPerPage.value) + (props.index + 1) && PreviewModule.isPreviewOpen;
  });

  const openPreviewModal = async (material, isHistoryView = false) => {
    isPreviewLoading.value = true;
    // check if the material is listed in the edited array
    material.IsInlineEdited = isEdited(material);
    isPreviewVisible.value = true;
    if (isHistoryView) {
      await HistoryService.getPublishedContentItem(material.s3ObjectKey).then(res => {
        PreviewModule.setPreviewContent(res);
      });
    } else if (material.IsVideo && !material.IsTranscript) {
      await PreviewService.getPreviewVideoContent(material).then(res => {
        PreviewModule.setPreviewContent(res);
      });
    } else {
      await PreviewService.getPreviewContent(material).then(res => {
        PreviewModule.setPreviewContent(res);
      });
    }
    isPreviewLoading.value = false;
    PreviewModule.setIsPreviewOpen(true);
    ContentModule.setPreviewedMaterialIndex(((pageNumber.value - 1) * itemsPerPage.value) + (previewMaterial.index + 1));
  };

  const closePreview = () => {
    isPreviewVisible.value = false;
    PreviewModule.setIsPreviewOpen(false);
  };
  const setPreviewInfo = (material, index, isHistoryView = false) => {
    previewMaterial.material = material;
    previewMaterial.index = index;
    openPreviewModal(material, isHistoryView);
  };

  const getTotalTitleCount = (item) => {
    if (isFavoritesView.value) {
      return ContentModule.favoritesDisplayedContentCount;
    } else if (isFoldersView.value) {
      return FolderModule.getCountOfMaterials;
    } else if (isHistoryView.value) {
      return HistoryModule.displayedContentCount;
    } else {
      return ContentModule.getTaxonomyTitleCount(item.Taxonomy.Code);
    }
  };

  const getNextIndex = (currentIndex) => {
    const newIndex = currentIndex + 1 === itemsPerPage.value ? 0 : currentIndex + 1;
    let contentIndex: number;
    if ((isHistoryView.value || isFavoritesView.value)) {
      if (currentIndex + 1 === itemsPerPage.value) {
        contentIndex = itemsPerPage.value * (pageNumber.value - 1);
      } else {
        contentIndex = (itemsPerPage.value * (pageNumber.value - 1)) + (currentIndex + 1);
      }
    } else {
      contentIndex = newIndex;
    }

    return { newIndex, contentIndex };
  };

  const getPreviousIndex = (currentIndex) => {
    const newIndex = currentIndex - 1 < 0 ? itemsPerPage.value - 1 : currentIndex - 1;
    let contentIndex = newIndex;
    if (isHistoryView.value || isFavoritesView.value) {
      if (currentIndex - 1 < 0) {
        contentIndex = (itemsPerPage.value * pageNumber.value) - 1;
      } else {
        contentIndex = (itemsPerPage.value * (pageNumber.value - 1)) + (currentIndex - 1);
      }
    }
    return { newIndex, contentIndex };
  };
  const setPreviewedMaterialIndex = (content, currentIndex, forward = false) => {
    let newIndex;
    let contentIndex;
    if (isFoldersView.value) {
      if (forward) {
        newIndex = currentIndex + 1;
      } else {
        newIndex = currentIndex - 1;
      }

      contentIndex = newIndex;
    } else if (forward) {
      // ({ newIndex, contentIndex } = getNextIndex(currentIndex)); This is cleaner, but dunno if it would work in IE11, dont wanna risk it
      const indexes = getNextIndex(currentIndex);
      newIndex = indexes.newIndex;
      contentIndex = indexes.contentIndex;
    } else {
      // ({ newIndex, contentIndex } = getPreviousIndex(currentIndex));
      const indexes = getPreviousIndex(currentIndex);
      newIndex = indexes.newIndex;
      contentIndex = indexes.contentIndex;
    }
    previewMaterial.index = newIndex;
    previewMaterial.material = content[contentIndex];
    openPreviewModal(previewMaterial.material, isHistoryView.value);
  };

  const showPreviousContentItem = (content) => {
    const currentIndex = previewMaterial.index;
    if (currentIndex - 1 < 0 && !isFoldersView.value) {
      if (ContentModule.previewedMaterialIndex - 1 > 0) {
        TableSettings.updatePageNumber(pageNumber.value - 1);
        closePreview();
        reOpenPreview.value = true;
      }

      if (isFavoritesView.value || isHistoryView.value || isFoldersView.value) {
        setPreviewedMaterialIndex(content, currentIndex);
      }
    } else {
      setPreviewedMaterialIndex(content, currentIndex);
    }
  };

  const showNextContentItem = (content) => {
    const currentIndex = previewMaterial.index;
    if (currentIndex + 1 >= itemsPerPage.value) {
      const titleCount = getTotalTitleCount(previewMaterial.material);

      if (currentIndex + 1 < titleCount && !isFoldersView.value) {
        TableSettings.updatePageNumber(pageNumber.value + 1);
        closePreview();
        reOpenPreview.value = true;
        nextPage.value = true;
      }

      if (isFavoritesView.value || isHistoryView.value || isFoldersView.value) {
        setPreviewedMaterialIndex(content, currentIndex, true);
      }
    } else {
      setPreviewedMaterialIndex(content, currentIndex, true);
    }
  };

  return { previewMaterial, isPreviewLoading, isPreviewVisible, reOpenPreview, nextPage, pageNumber, itemsPerPage, isOpenInPreview, openPreviewModal, closePreview, setPreviewInfo, getTotalTitleCount, showPreviousContentItem, showNextContentItem };
}
