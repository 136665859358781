
import { defineComponent, ref } from "vue";
import { useContentStore } from "@/store/modules/Content/content";
import { usePreviewStore } from "@/store/modules/Preview/preview";
import EditContentService from "../services/editContentService";
import PreviewService from "../services/previewService";

export default defineComponent({
  name: "RestoreContentModal",
  props: {
    value: {
      type: Object,
      required: true,
    },
    material: {
      type: Object,
      required: true,
    },
  },
  setup (props, { emit }) {
    const ContentModule = useContentStore();
    const PreviewModule = usePreviewStore();
    const isRestoreLoading = ref(false);
    const onRestore = async () => {
      emit("onRestoreLoading", true);
      isRestoreLoading.value = true;
      await EditContentService.deleteEditContent({
        contentId: props.material.ContentId,
        contentTypeId: props.material.ContentTypeId,
      }).then(() => {
        const data = {
          contentId: props.material.ContentId,
          taxonomyCode: props.material.Taxonomy?.Code ? props.material.Taxomony?.Code : "",
          isEdited: false,
        };
        ContentModule.toggleEdited(data);
      });
      await PreviewService.getPreviewContent(props.material).then(res => {
        PreviewModule.setPreviewContent(res);
        isRestoreLoading.value = false;
        props.value.isVisible = false;
        emit("onRestoreLoading", false);
      });
    };

    return {
      isRestoreLoading,
      onRestore,
    };
  },
});
