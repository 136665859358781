import { defineStore } from "pinia";
import { ref, computed } from "vue";
import PrintService from "../../../services/printService";
import PublishedHistory from "../../../services/publishedHistoryService";
import { useEducationCartStore } from "../EducationCart/educationCart";
import { useContentStore } from "../Content/content";
import { usePatientStore } from "../Patient/patient";
import { usePreviewStore } from "../Preview/preview";
import { useUserStore } from "../User/user";
import { useUIConfigurationStore } from "../UIConfiguration/uiConfiguration";
import printContent from "../../../utility/print.js";
import { ContentList, Link, Language, EnglishOtherLanguageRequest } from "@/models/content/content";
import { TaxonomyCode } from "@/models/taxonomy/taxonomy";
export const usePrintShareStore = defineStore("PrintShare", () => {
  const content = ref<string>("");
  const materialsForPrintShare = ref<ContentList[]>([]);
  const singleMaterialForPrintShare = ref<ContentList>({
    ContentId: "",
    ContentOrigin: -1,
    ContentTypeId: -1,
    EnglishOtherLanguageRequest: {} as EnglishOtherLanguageRequest,
    Id: -1,
    IsCustom: false,
    IsFavorite: false,
    IsInlineEdited: false,
    IsTranscript: false,
    IsVideo: false,
    Language: {} as Language,
    LanguageCode: "",
    Link: {} as Link,
    OtherLanguages: [],
    ParentFolderId: "",
    ParentFolderName: "",
    PublishedDate: "",
    SearchRank: -1,
    SortOrder: -1,
    Taxonomy: {} as TaxonomyCode,
    ThumbnailImageUri: "",
    Title: "",
    key: "",
  } as ContentList);
  const operating = ref({});
  const multiPrint = ref(false);
  const isSnackbarVisible = ref(false);
  const showEmailDialog = ref(false);
  const showPrePublishDialog = ref(false);
  const triggerEmailNotification = ref(true);
  const triggerPreviewReload = ref(false);
  const isPrint = ref(true);
  const isPrintOnly = ref(false);

  const distributionActions = {
    Printed: 0,
    Published: 1,
    PrintedAndPublished: 2,
    Previewed: 3,
    PrintedOnly: 4,
  };

  const getContent = computed(() => content.value);
  const getOperating = computed(() => operating.value);
  const getSnackbarVisibility = computed(() => isSnackbarVisible.value);
  const getTriggerPreviewReload = computed(() => triggerPreviewReload.value);
  const getSuccessMessage = computed(() => {
    const UserModule = useUserStore();
    return showEmailDialog.value ? "Published successfully" : isPrintOnly.value ? "Printed Successfully" : UserModule.getDisplaySettings?.modals?.prePublishModal?.successMessage ?? "Published successfully";
  });
  const triggerEmailDialog = computed(() => {
    const UserModule = useUserStore();
    const PatientModule = usePatientStore();
    const UIConfigurationModule = useUIConfigurationStore();
    let showDialog = !PatientModule.getEmail;
    showDialog = showDialog && UserModule.isPatientPortalEnabled;
    showDialog = showDialog && UIConfigurationModule.getUIConfiguration.ShowPortalPluginEmailModal;

    return showDialog;
  });

  const triggerPrePublishDialog = computed(() => {
    const UIConfigurationModule = useUIConfigurationStore();
    const showDialog = isPrint.value
      ? UIConfigurationModule.getUIConfiguration.ShowPrintPublishModal
      : UIConfigurationModule.getUIConfiguration.ShowPublishModal;

    return showDialog;
  });

  const getShowEmailDialog = computed(() => showEmailDialog.value);
  const getShowPrePublishDialog = computed(() => showPrePublishDialog.value);

  function setPrintContent (newContent: string) {
    content.value = newContent;
  }

  function toggleSnackbar (flag: boolean) {
    isSnackbarVisible.value = flag;
  }

  async function publishItems ({ cartItems }) {
    const UserModule = useUserStore();
    const CartModule = useEducationCartStore();
    const PatientModule = usePatientStore();
    const ContentModule = useContentStore();
    const PreviewModule = usePreviewStore();
    let res;
    const cart = Object.assign({}, CartModule.getEducationCart);
    cart.CartItems = cartItems;
    cart.AddPatientName ? cart.Name = PatientModule.getPatient.DisplayName : cart.Name = "";
    cart.TimeZoneOffset = new Date().getTimezoneOffset();
    cart.PublishToPortal = true;
    cart.RemovePrintedItems = false;
    cart.PrintResultType = 1;
    cart.PrintCopies = 1;

    if (isPrintOnly.value) {
      cart.AddToAvs = false;
      cart.DistributionAction = distributionActions.PrintedOnly;
      res = await PrintService.print(cart);
      setPrintContent(res);
      printContent(res);
    } else if (isPrint.value) {
      cart.PatientEmailAddress = PatientModule.getEmail;
      cart.DistributionAction = distributionActions.PrintedAndPublished;
      res = await PrintService.print(cart);
      setPrintContent(res);
      printContent(res);
    } else {
      cart.PatientEmailAddress = PatientModule.getEmail;
      cart.DistributionAction = distributionActions.Published;
      res = await PrintService.share(cart);
    }

    if (!res) return;

    if (UserModule.getDisplaySettings.cartItemLimit != null) {
      UserModule.setUserSettingsCartItemLimit(UserModule.getDisplaySettings.cartItemLimit - cart.CartItems.length);
    }

    if (multiPrint.value) {
      cartItems.forEach(content => {
        const taxonomyCode = content.Taxonomy?.Code ?? "";
        const contentId = content.ContentId;
        const contentTypeId = content.ContentTypeId;
        ContentModule.toggleEdited({ contentId, taxonomyCode, isEdited: false, contentTypeId });
      });
      ContentModule.updateSelectedMaterials([]);
    } else {
      triggerPreviewReload.value = true;
      if (!PreviewModule.isPreviewOpen) {
        const taxonomyCode = cartItems[0]?.Taxonomy?.Code ?? "";
        const contentTypeId = cartItems[0]?.ContentTypeId;
        const contentId = cart.CartItems[0]?.ContentId;
        ContentModule.toggleEdited({ contentId, taxonomyCode, isEdited: false, contentTypeId });
      }
    }

    const newPublishedHistory = await PublishedHistory.getPublishedHistory();
    ContentModule.setPublishedMaterials(newPublishedHistory);

    toggleSnackbar(true);
    if (multiPrint.value || singleMaterialForPrintShare.value.ContentId === cart.CartItems[0].ContentId) {
      materialsForPrintShare.value = [];
      singleMaterialForPrintShare.value = {} as ContentList;
    }
  }

  async function printOrShareMaterial (printInfo) {
    const UserModule = useUserStore();
    const UIConfigurationModule = useUIConfigurationStore();
    if (printInfo.isPrint !== undefined) isPrint.value = printInfo.isPrint;
    if (printInfo.isPrintOnly !== undefined) isPrintOnly.value = printInfo.isPrintOnly;
    if (printInfo.triggerEmailNotification !== undefined) triggerEmailNotification.value = printInfo.triggerEmailNotification;
    if (printInfo.multiPrint !== undefined) multiPrint.value = printInfo.multiPrint;

    if (multiPrint.value) {
      if (printInfo.materials !== undefined) materialsForPrintShare.value = printInfo.materials;
    } else {
      if (printInfo.materials !== undefined) singleMaterialForPrintShare.value = printInfo.materials;
    }

    let cartItems;
    if (multiPrint.value) {
      cartItems = materialsForPrintShare.value;
    } else {
      cartItems = [singleMaterialForPrintShare.value];
    }

    if (UserModule.getDisplaySettings.cartItemLimit != null && UserModule.getDisplaySettings.cartItemLimit <= 0) {
      UIConfigurationModule.setCartLimitExceededDialogVisibility(UserModule.getDisplaySettings.cartItemLimit <= 0);
      return;
    }
    if (!cartItems || cartItems.length === 0) return Promise.resolve;
    showEmailDialog.value = (triggerEmailDialog.value && triggerEmailNotification.value);

    if (triggerEmailDialog.value && triggerEmailNotification.value) return;

    showPrePublishDialog.value = (triggerPrePublishDialog.value && printInfo.triggerPrePublishNotification !== false);

    if (triggerPrePublishDialog.value && printInfo.triggerPrePublishNotification !== false) return;

    await publishItems({ cartItems });
  }

  function setShowPrePublishDialog (flag: boolean) {
    showPrePublishDialog.value = flag;
  }

  function togglePreviewReload (flag: boolean) {
    triggerPreviewReload.value = flag;
  }

  function updateTriggerEmailNotificationDialog (flag: boolean) {
    triggerEmailNotification.value = flag;
  }

  return { multiPrint, isSnackbarVisible, showEmailDialog, showPrePublishDialog, triggerEmailDialog, triggerPreviewReload, isPrint, isPrintOnly, materialsForPrintShare, singleMaterialForPrintShare, content, operating, getContent, getOperating, getSnackbarVisibility, getTriggerPreviewReload, getSuccessMessage, getShowEmailDialog, getShowPrePublishDialog, setPrintContent, toggleSnackbar, publishItems, printOrShareMaterial, setShowPrePublishDialog, togglePreviewReload, updateTriggerEmailNotificationDialog };
});
