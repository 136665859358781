import { SamlDetails, Details } from "@/models/diagnostics/diagnostics";
import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useDiagnosticsStore = defineStore("Diagnostics", () => {
  const diagnostics = ref<Details>({} as Details);
  const samlDiagnostics = ref<SamlDetails>({} as SamlDetails);

  const getDetails = computed(() => diagnostics.value);
  const getSamlDetails = computed(() => samlDiagnostics.value);

  function setDetails (details: Details) {
    diagnostics.value = details;
  }

  function setSamlDetails (details: SamlDetails) {
    samlDiagnostics.value = details;
  }

  return { diagnostics, samlDiagnostics, getDetails, getSamlDetails, setDetails, setSamlDetails };
});
