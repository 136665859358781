
import { defineComponent, ref, computed, reactive, watch, onMounted, onUnmounted } from "vue";
import PrintMenu from "./PrintMenu.vue";
import { usePreviewStore } from "@/store/modules/Preview/preview";
import { useContentStore } from "@/store/modules/Content/content";
import { useTableSettingsStore } from "@/store/modules/TableSettings/tableSettings";
import { useHistoryStore } from "@/store/modules/History/history";
import { useFoldersStore } from "@/store/modules/Folders/folders";
import { usePrintShareStore } from "../store/modules/PrintShare/printShare";
import { useUIConfigurationStore } from "@/store/modules/UIConfiguration/uiConfiguration";
import { useUserStore } from "@/store/modules/User/user";
import Editor from "../assets/lib/@tinymce/tinymce-vue";
import tinymceConfig from "../utility/tinymceConfig";
import EditContentService from "../services/editContentService";
import PreviewService from "../services/previewService";
import printShareFunctionality from "@/composables/PrintOrShare";
import FavoriteIcon from "./icons/FavoriteIcon.vue";
import UnFavoriteIcon from "./icons/UnFavoriteIcon.vue";
import ShareIcon from "./icons/ShareIcon.vue";

const LanguageSelector = () => import("./LanguageSelector.vue");
const RestoreContentModal = () => import("./RestoreContentModal.vue");
const VideoPlayer = () => import("./VideoPlayer.vue");

export default defineComponent({
  name: "PreviewModal",
  components: {
    Editor,
    RestoreContentModal,
    VideoPlayer,
    LanguageSelector,
    PrintMenu,
    FavoriteIcon,
    UnFavoriteIcon,
    ShareIcon,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    material: {
      type: Object,
      default: null,
    },
    historyView: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const PrintShareModule = usePrintShareStore();
    const FoldersModule = useFoldersStore();
    const ContentModule = useContentStore();
    const PreviewModule = usePreviewStore();
    const TableSettingsModule = useTableSettingsStore();
    const UserModule = useUserStore();
    const HistoryModule = useHistoryStore();
    const UiConfigurationModule = useUIConfigurationStore();
    const DIALOG_WIDTH = 976;
    const videoContent = computed(() => PreviewModule.videoContent);
    const previewContent = computed(() => {
      return PreviewModule.getContent;
    });
    const editableContent = computed(() => {
      return PreviewModule.getEditableContent;
    });
    const editor = ref();
    const isFavoritesView = computed(() => TableSettingsModule.viewingFavorites);
    const isInEditingMode = ref(false);
    const isEditLoading = ref(false);
    const isActionsMenuOpen = ref(false);
    const isRestoreModalVisible = reactive({ isVisible: false });
    const isRestoreLoading = ref(false);
    const isGoingToFavoriteService = ref(false);
    const disabled = ref(false);
    const windowWidth = ref(0);
    const windowHeight = ref(window.innerHeight);
    const userHasEditAccess = UserModule.getDocumentEditingEnabled;
    const { isPrinting, isSharing, printOrShareMaterials } =
      printShareFunctionality();

    const userDisplaySettings = computed(() => UserModule.getDisplaySettings);
    const uiConfiguration = computed(
      () => UiConfigurationModule.uiConfiguration
    );
    const isInFavorites = computed((): boolean => {
      const favorites = ContentModule.getFavorites;
      return !!favorites[props.material.FrontEndId];
    });
    const triggerPreviewReload = computed(
      () => PrintShareModule.triggerPreviewReload
    );

    const setWindowDimensions = () => {
      windowWidth.value = window.innerWidth;
      windowHeight.value = window.innerHeight;
    };

    onMounted(() => {
      window.addEventListener("resize", setWindowDimensions);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", setWindowDimensions);
    });

    const toggleFavorite = async (contentTypeId, isFavorite) => {
      disabled.value = isGoingToFavoriteService.value = true;
      await ContentModule.toggleFavorite({
        material: props.material,
        contentTypeId,
        isFavorite,
      });

      // removal of favorite will set previous item in array
      // reuse page behavior of navigation button
      if (isFavorite === false && isFavoritesView.value === true) {
        emit("get-previous-content");
      }

      disabled.value = isGoingToFavoriteService.value = false;
    };

    const onRestoreLoading = (value) => {
      isRestoreLoading.value = value;
    };

    const changeLanguage = async () => {
      emit("onChangeLanguage", props.material);
    };

    const showPrintButton = computed((): boolean => {
      return UserModule.getDisplaySettings.printButton?.visibility === 1;
    });

    const isEditOn = computed(() => {
      let editDocument = false;
      if (
        uiConfiguration.value.ContentTypesEnabledForEdit.includes(
          props.material.ContentTypeId
        )
      ) {
        editDocument = userHasEditAccess;
      }
      return editDocument && !props.material.IsVideo;
    });

    const hasContentChanged = ref(false);
    const editedContent = ref("");

    const onSave = async () => {
      isEditLoading.value = true;
      const el = document.createElement("html");
      el.innerHTML = PreviewModule.getContent;
      const hsBody =
        el.querySelector("#HSBody") ?? document.createElement("div");
      hsBody.innerHTML = editedContent.value;

      const content = hsBody.outerHTML.replace('contenteditable="true"', "");

      await EditContentService.postEditContent({
        content: content,
        contentId: props.material.ContentId,
        contentTypeId: props.material.ContentTypeId,
      }).then((r) => {
        if (r.status === 1) {
          const data = {
            contentId: props.material.ContentId,
            taxonomyCode: props.material.Taxonomy
              ? props.material.Taxonomy.Code
              : "",
            isEdited: true,
            contentTypeId: props.material.ContentTypeId,
          };
          ContentModule.toggleEdited(data);
          PreviewModule.setPreviewContent(el.outerHTML);
          isInEditingMode.value = false;
          hasContentChanged.value = false;
        }
        isEditLoading.value = false;
      });
    };

    watch(isInEditingMode,
      (newVal) => {
        if (newVal) {
          // short timeout to allow for tinymce to build its divs, etc, before focusing on them.
          setTimeout(() => editor.value.$el.focus(), 50);
        }
      },
      { flush: "post" }
    );

    watch(triggerPreviewReload, async (newVal, oldVal) => {
      // if the preview dialog was open, and reload was triggered
      // retrieve the old content and delete the new content
      if (
        props.isOpen &&
        props.material.IsInlineEdited &&
        newVal &&
        newVal !== oldVal
      ) {
        isEditLoading.value = true;
        // set content to unedited for a refresh
        const data = {
          contentId: props.material.ContentId,
          taxonomyCode: props.material.Taxonomy.Code,
          isEdited: false,
          contentTypeId: props.material.ContentTypeId,
        };
        ContentModule.toggleEdited(data);
        // if the previewed item was printed or shared, get the original
        if (props.material.IsVideo && !props.material.IsTranscript) {
          await PreviewService.getPreviewVideoContent(props.material).then(
            (res) => {
              PreviewModule.setPreviewContent(res);
            }
          );
        } else {
          await PreviewService.getPreviewContent(props.material).then((res) => {
            PreviewModule.setPreviewContent(res);
          });
        }

        isEditLoading.value = false;

        EditContentService.deleteEditContent({
          contentId: props.material.ContentId,
          contentTypeId: props.material.ContentTypeId,
        });
        editedContent.value = "";
      }
      // no matter what, toggle the preview reload to false
      PrintShareModule.togglePreviewReload(false);
    });

    const showPrintMenuTooltip = ref(false);

    watch(() => props.isOpen, (isOpen) => {
      if (isOpen) {
        setWindowDimensions();
      }
    });
    const calcButtonPlacement = computed(() => {
      const normalizedWindowWidth = Math.floor(windowWidth.value);
      const normalizedWindowHeight = Math.floor(windowHeight.value);
      return {
        x: Math.floor(((normalizedWindowWidth - DIALOG_WIDTH) / 2) - 60),
        y: normalizedWindowHeight / 2,
      };
    });

    const selectedPreviewIndex = computed(() => ContentModule.previewedMaterialIndex);

    const displayedContentCount = computed(() => {
      if (TableSettingsModule.viewingHistory) {
        return HistoryModule.displayedContentCount;
      } else if (TableSettingsModule.viewingFavorites) {
        return ContentModule.favoritesDisplayedContentCount;
      } else if (TableSettingsModule.viewingFolders) {
        return FoldersModule.getCountOfMaterials;
      }
      return ContentModule.displayedContentCount;
    });

    return {
      DIALOG_WIDTH,
      isEditLoading,
      editableContent,
      previewContent,
      videoContent,
      isActionsMenuOpen,
      isRestoreModalVisible,
      isRestoreLoading,
      isPrinting,
      isSharing,
      onRestoreLoading,
      toggleFavorite,
      changeLanguage,
      isInEditingMode,
      isEditOn,
      printOrShareMaterials,
      isInFavorites,
      tinymceConfig,
      hasContentChanged,
      onSave,
      editedContent,
      showPrintButton,
      editor,
      uiConfiguration,
      userHasEditAccess,
      userDisplaySettings,
      isGoingToFavoriteService,
      disabled,
      showPrintMenuTooltip,
      calcButtonPlacement,
      selectedPreviewIndex,
      displayedContentCount,
    };
  },
});
